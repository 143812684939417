<template>
    <div class="card z-index-2">
        <div class="pb-0 card-header mb-0">
            <h6>{{ title }}</h6>
            <!--  eslint-disable-next-line vue/no-v-html -->
            <p v-if="description" class="text-sm" v-html="description"/>
        </div>
        <div class="p-3 card-body">
            <div class="chart">
                <canvas :id="id" class="chart-canvas" :height="height"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    name: "GradientLineChart",
    props: {
        id: {
            type: String,
            required: false,
            default: "line"
        },
        height: {
            type: String,
            default: "300",
        },
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        chart: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        var gradientLineChart = document.getElementById(this.id).getContext("2d");

        let chartStatus = Chart.getChart(this.id);
        if (chartStatus != undefined) {
            chartStatus.destroy();
        }

        const createGradient = (ctx, chartArea, color1, color2) => {
            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, color1);
            gradient.addColorStop(1, color2);
            return gradient;
        };

        const adjustAlpha = (rgba, alpha) => {
            return rgba.replace(/rgba?\(([^)]+)\)/, (match, color) => {
                return `rgba(${color.split(',').slice(0, 3).join(',')}, ${alpha})`;
            });
        };

        const datasets = this.chart.datasets.map((dataset) => {
            return {
                label: dataset.label,
                tension: 0.4,
                borderWidth: 3,
                pointRadius: 0,
                borderColor: dataset.backgroundColor,
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) {
                        return null;
                    }

                    const color1 = adjustAlpha(dataset.backgroundColor, 0.5);
                    const color2 = adjustAlpha(dataset.backgroundColor, 0.3);

                    return createGradient(ctx, chartArea, color1, color2);
                },
                fill: true,
                data: dataset.data,
                maxBarThickness: 6,
            };
        });

        new Chart(gradientLineChart, {
            type: "line",
            data: {
                labels: this.chart.labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                    },
                },
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                scales: {
                    y: {
                        grid: {
                            drawBorder: true,
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            borderDash: [5, 5],
                        },
                        ticks: {
                            display: true,
                            padding: 10,
                            color: "#ccc",
                            font: {
                                size: 11,
                                family: "Jost",
                                style: "sans-serif",
                                lineHeight: 2,
                            },
                        },
                    },
                    x: {
                        grid: {
                            drawBorder: true,
                            display: true,
                            drawOnChartArea: false,
                            drawTicks: false,
                            borderDash: [5, 5],
                        },
                        ticks: {
                            display: true,
                            color: "#ccc",
                            padding: 20,
                            font: {
                                size: 11,
                                family: "Jost",
                                style: "sans-serif",
                                lineHeight: 2,
                            },
                        },
                    },
                },
            },
        });
    },
};
</script>
