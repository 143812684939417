export const navigationGroups = [
    {
        title: 'Content',
        roles: ['admin', 'creator', 'manager', 'staff'],
        items: [
            { routeName: 'title.list', text: 'Titles', miniIcon: 'fa-headphones' },
            { routeName: 'author.list', text: 'Authors', miniIcon: 'fa-user' },
            { routeName: 'series.list', text: 'Series', miniIcon: 'fa-user' },
            { routeName: 'cast.list', text: 'Cast', miniIcon: 'fa-user' },
            { routeName: 'contentType.list', text: 'Content Types', miniIcon: 'fa-user' },
            { routeName: 'category.list', text: 'Genres', miniIcon: 'fa-tags' },
            { routeName: 'page.list', text: 'Pages', miniIcon: 'fa-file' },
            { routeName: 'tag.list', text: 'Tags', miniIcon: 'fa-hashtag' },
            { routeName: 'channel.list', text: 'Channels', miniIcon: 'fa-tv' },
            { routeName: 'rating.list', text: 'Ratings', miniIcon: 'far fa-meh' },
            { routeName: 'review.list', text: 'Reviews', miniIcon: 'fa-star' },
            { routeName: 'slider.list', text: 'Sliders and Banners', miniIcon: 'fa-image' },
        ],
    },
    {
        title: 'Insights',
        roles: ['admin', 'reporting', 'manager'],
        items: [
            { routeName: 'insights.marketing', text: 'Marketing Analytics', miniIcon: 'fa-chart-bar' },
            { routeName: 'insights.revenue', text: 'Sales Analytics', miniIcon: 'fa-chart-line' },
            { routeName: 'insights.customer', text: 'Customer Insights', miniIcon: 'fa-chart-pie' },
            { routeName: 'customer.list', text: 'Customers', miniIcon: 'fa-user' },
        ],
    },
    {
        title: 'Reports and Metrics',
        roles: ['admin', 'reporting', 'manager'],
        items: [
            { routeName: 'metrics.overview', text: 'Overview', miniIcon: 'fa-chart-bar' },
            { routeName: 'metrics.sales', text: 'Sales Reports', miniIcon: 'fa-chart-line' },
            { routeName: 'metrics.users', text: 'User Reports', miniIcon: 'fa-user' },
        ]
    },
    {
        title: 'Sales',
        roles: ['admin', 'reporting', 'manager'],
        items: [
            { routeName: 'subscriptionPlan.list', text: 'Subscription Plans', miniIcon: 'fa-cube' },
            { routeName: 'userPurchase.list', text: 'Purchases', miniIcon: 'fa-list' },
            { routeName: 'transaction.list', text: 'Transactions', miniIcon: 'fa-chart-pie' },
        ],
    },
    {
        title: 'Marketing',
        roles: ['admin', 'marketer', 'manager'],
        items: [
            { routeName: 'adProvider.list', text: 'Ad Providers', miniIcon: 'fa-ship' },
            //  { routeName: 'campaign.list', text: 'Campaigns', miniIcon: 'fa-rocket' },
        ],
    },
    {
        title: 'General',
        roles: ['admin'],
        items: [
            { routeName: 'user.list', text: 'Users', miniIcon: 'fa-user-astronaut' },
            { routeName: 'role.list', text: 'Roles', miniIcon: 'fa-shield-alt' },
            { routeName: 'appSetting.list', text: 'Settings', miniIcon: 'fa-cogs' },
            { routeName: 'region.list', text: 'Regions', miniIcon: 'fa-globe' },
            { routeName: 'app.list', text: 'Apps', miniIcon: 'fa-mobile' },
        ],
    },
];