import store from "../store";

export default async function admin({ next, router }) {
    try {
        const role = store.getters["profile/me"]?.roles[0]?.name;

        if (role !== "admin") {
            return router.push({ name: "dashboard" });
        }

        next();
    } catch (error) {
        console.error('Error in admin middleware:', error);
        next({ name: "error.500" }); // Navigate to an error page or handle as needed
    }
}
