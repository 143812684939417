<template>
    <div class="card">
        <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
                <h6 class="mb-0">Revenue</h6>
                <button type="button"
                        class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title
                        data-bs-original-title="See which ads perform better"
                >
                    <i class="fas fa-info" aria-hidden="true"></i>
                </button>
            </div>
            <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-success"></i>
          <span class="text-xs text-dark">Facebook Ads</span>
        </span>
                <span class="badge badge-md badge-dot me-4">
          <i class="bg-dark"></i>
          <span class="text-xs text-dark">Google Ads</span>
        </span>
            </div>
        </div>
        <div class="p-3 card-body">
            <div class="chart">
                <canvas :id="chartId" class="chart-canvas" height="300"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
    name: "RevenueChartCard",
    data() {
        return {
            chartId: "chart-line",
        };
    },
    mounted() {
        var lineChart = document.getElementById(this.chartId).getContext("2d");
        var gradientStroke1 = lineChart.createLinearGradient(0, 230, 0, 50);

        gradientStroke1.addColorStop(1, "rgba(94, 114, 228,0.2)");
        gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

        var gradientStroke2 = lineChart.createLinearGradient(0, 230, 0, 50);

        gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
        gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
        gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors
        // Line chart

        let chartStatus = Chart.getChart(this.id);
        if (chartStatus != undefined) {
            chartStatus.destroy();
        }

        new Chart(lineChart, {
            type: "line",
            data: {
                labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [
                    {
                        label: "Facebook Ads",
                        tension: 0.4,
                        borderWidth: 0,
                        pointRadius: 2,
                        pointBackgroundColor: "#4BB543 ",
                        borderColor: "#4BB543 ",
                        // eslint-disable-next-line no-dupe-keys
                        borderWidth: 3,
                        backgroundColor: gradientStroke1,
                        fill: true,
                        data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
                        maxBarThickness: 6,
                    },
                    {
                        label: "Google Ads",
                        tension: 0.4,
                        borderWidth: 0,
                        pointRadius: 2,
                        pointBackgroundColor: "#3A416F",
                        borderColor: "#3A416F",
                        // eslint-disable-next-line no-dupe-keys
                        borderWidth: 3,
                        backgroundColor: gradientStroke2,
                        fill: true,
                        data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
                        maxBarThickness: 6,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                scales: {
                    y: {
                        grid: {
                            drawBorder: false,
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: false,
                            borderDash: [5, 5],
                        },
                        ticks: {
                            display: true,
                            padding: 10,
                            color: "#9ca2b7",
                        },
                    },
                    x: {
                        grid: {
                            drawBorder: false,
                            display: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            borderDash: [5, 5],
                        },
                        ticks: {
                            display: true,
                            color: "#9ca2b7",
                            padding: 10,
                        },
                    },
                },
            },
        });
    },
};
</script>
