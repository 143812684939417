import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const customer = (components) => createCrudRoutes("customer", "/customers", {
    roles: ['admin'],
    components: components,
    listProps: {
        list: {
            headers: [
                { key: 'name', title: 'Name', format: 'customerProfile' },
                { key: 'subscribed', title: 'Subscribed', format: 'subscribed' },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'status', title: 'Status', format: 'status' },
                { key: 'action', title: 'Action', format: 'actionEdit', sortable: false }
            ],
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'email', label: 'Email', type: 'email' },
                    ]
                ],
                side: [
                    [
                        { key: 'profile_image', label: '', type: 'image', resource: 'channel', uploadKey: 'id' },
                    ]
                ]
            }
        }
    }
});

export {
    customer
}
