<template>
    <div class="filter-bar px-3 mb-3">
        <div v-for="(filter, index) in getFilters"
             :key="index"
             class="filter-element"
        >
            <template v-if="filter.type === 'search'">
                <div class="input-element-group">
                    <span class="input-group-icon">
                        <i class="fas fa-search"></i>
                    </span>
                    <input v-model="filter.value"
                           placeholder="Search..."
                           class="form-control search-input"
                           @input="debouncedSearch(filter)"/>
                </div>
            </template>

            <template v-if="filter.type === 'buttonGroup'">
                <div class="status-buttons input-group bg-light">
                    <button v-for="(option, inx) in filter.options"
                            :key="inx"
                            class="btn btn-md mb-0 shadow-none"
                            :class="{ 'btn-primary': isOptionActive(filter, option) }"
                            @click="updateFilterValue(filter, option)"
                    >
                        {{ option }}
                    </button>
                </div>
            </template>

            <template v-if="filter.type === 'select'">
                <select v-model="filter.value"
                        class="form-control"
                        @change="updateFilterValue(filter, $event.target.value)"
                >
                    <option value="">{{ filter.placeholder || 'Select...' }}</option>
                    <option v-for="option in filter.options"
                            :key="option.value"
                            :value="option.value"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </template>

            <template v-if="filter.type === 'toggle'">
                <button class="btn btn-md mb-0 shadow-none"
                        :class="{ 'btn-primary': isToggleActive(filter), 'bg-light': !isToggleActive(filter) }"
                        @click="toggleFilter(filter)"
                >
                    {{ filter.label }}
                </button>
            </template>
        </div>

        <div v-if="areFiltersApplied" class="filter-element">
            <button class="btn btn-link text-muted mb-0"
                    @click="resetFilters"
            >
                Reset
            </button>
        </div>

        <div class="filter-element ms-auto d-flex align-items-center">
            <span class="pe-2">
                Display
            </span>
            <select :value="perPage"
                    class="form-control"
                    @change="updateRecordsCount($event.target.value)"
            >
                <option v-for="count in recordsCounts"
                        :key="count"
                        :value="count"
                >
                    {{ count }} records
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';

export default {
    name: "UIListFilterBar",
    props: {
        filters: {
            type: Array,
            required: true
        },
        recordsCounts: {
            type: Array,
            default: () => [10, 25, 50, 100]
        },
        perPage: {
            type: Number,
            required: true
        }
    },
    emits: ['update:filters', 'update:perPage'],
    setup() {
        const route = useRoute();
        return { route };
    },
    data() {
        return {
            localFilters: [],
            selectedRecordsCount: this.initialPerPage,
            searchTimeout: null
        };
    },
    computed: {
        areFiltersApplied() {
            return this.localFilters.some(filter => filter.value);
        },
        getFilters() {
            return this.localFilters;
        }
    },
    watch: {
        filters: {
            immediate: true,
            handler(newFilters) {
                this.localFilters = JSON.parse(JSON.stringify(newFilters));
            }
        }
    },
    created() {
        this.initializeFiltersFromQuery();
    },
    beforeUnmount() {
        clearTimeout(this.searchTimeout);
    },
    methods: {
        updateFilterValue(filter, value) {
            filter.value = value;
            this.emitFilterUpdate();
        },
        isOptionActive(filter, option) {
            const queryFilters = this.getQueryFilters();
            const queryValue = queryFilters[filter.name];
            return filter.type === 'toggle' ? queryValue === true : queryValue === option;
        },
        isToggleActive(filter) {
            const queryFilters = this.getQueryFilters();
            const queryValue = queryFilters[filter.name];
            return filter.type === 'toggle' && queryValue === 1;
        },
        emitFilterUpdate() {
            const activeFilters = this.localFilters.filter(filter => {
                if (filter.type === 'toggle') {
                    return filter.value === 1;
                }
                return filter.value !== '' && filter.value !== null && filter.value !== undefined;
            });
            this.$emit('update:filters', activeFilters);
            this.updateQueryParams();
        },
        updateRecordsCount(value) {
            this.$emit('update:perPage', parseInt(value));
        },
        resetFilters() {
            this.localFilters = this.localFilters.map(filter => ({
                ...filter,
                value: ''
            }));
            this.emitFilterUpdate();
        },
        initializeFiltersFromQuery() {
            const queryFilters = this.getQueryFilters();
            this.localFilters.forEach(filter => {
                const queryValue = queryFilters[filter.name];
                if (queryValue !== undefined) {
                    if (filter.type === 'toggle') {
                        filter.value = queryValue === '1' ? 1 : 0;
                    } else {
                        filter.value = queryValue;
                    }
                } else if (filter.type === 'toggle') {
                    filter.value = 0;
                }
            });
            this.emitFilterUpdate();
        },
        updateQueryParams() {
            const query = { ...this.route.query };
            const filterParams = {};
            this.localFilters.forEach(filter => {
                if (filter.value) {
                    filterParams[filter.name] = filter.value;
                }
            });
            if (Object.keys(filterParams).length > 0) {
                query.filter = JSON.stringify(filterParams);
            } else {
                delete query.filter;
            }
            this.$router.replace({ query });
        },
        getQueryFilters() {
            const { filter } = this.route.query;
            return filter ? JSON.parse(filter) : {};
        },
        debouncedSearch(filter) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.updateFilterValue(filter, filter.value);
            }, 1000); // 1000ms = 1 second
        },
        toggleFilter(filter) {
            filter.value = filter.value === 1 ? 0 : 1;
            this.emitFilterUpdate();
        }
    }
}
</script>

<style scoped>
.filter-bar {
    display: flex;
    gap: .5rem;
    align-items: center;
    background: #f8f9fa;
    margin: -0.8rem .2rem 0;
    padding: .6rem !important;
    border-radius: .8rem;
}

.input-element-group {
    position: relative;
}

.input-element-group input {
    padding-left: 2.4rem;
}

span.input-group-icon {
    position: absolute;
    width: 2.4rem;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
