import Swal from 'sweetalert2'

export const swal = {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        show(context, options) {
            return new Swal({
                toast: true,
                position: 'top-right',
                iconColor: 'white',
                width: options.width ? options.width : 300,
                text: options.message,
                customClass: {
                    popup: options.type === 'success' ? 'colored-toast-success' : 'colored-toast-error',
                },
                showConfirmButton: false,
                showCloseButton: true,
                timer: 2000,
                timerProgressBar: true,
            })
        },
        showConfirmationDelete() {
            return new Swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'btn bg-gradient-success',
                    cancelButton: 'btn bg-gradient-danger',
                },
                buttonsStyling: false,
            })
        },
    }
}
