import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { generateDynamicModule } from "/@/helpers/dynamicModuleGenerator";
import { loading } from './loading.module';
import { auth } from "./auth.module";
import { profile } from "./profile.module";
import { swal } from "./swal.module";
import ApiService from "/@/services/api.service";
import { metrics } from "./metrics.module";
import { titleRecommendation } from "./titleRecommendations.module";

const appSetting = generateDynamicModule({
    moduleName: "appSetting",
    resource: "app-settings",
    useApp: true,
});

const permission = generateDynamicModule({
    moduleName: "permission",
    resource: "permissions"
});

const userPurchase = generateDynamicModule({
    moduleName: "userPurchase",
    resource: "user-purchases",
    include: "include=title,user,transaction"
});

const user = generateDynamicModule({
    moduleName: "user",
    resource: "users",
    include: "include=roles",
    useApp: true,
});

const customer = generateDynamicModule({
    moduleName: "customer",
    resource: "users",
    include: "include=roles&filter[roles]=customer",
    useApp: false,
});

const subscriptionPlan = generateDynamicModule({
    moduleName: "subscriptionPlan",
    resource: "subscription-plans"
});

const author = generateDynamicModule({
    moduleName: "author",
    resource: "authors",
    useApp: true,
});

const cast = generateDynamicModule({
    moduleName: "cast",
    resource: "casts",
    useApp: true,
});


const roleApiService = new ApiService("roles", {
    include: '/permissions'
});

const role = generateDynamicModule({
    moduleName: "role",
    resource: "roles",
    include: "include=permissions",
    actions: {
        async editPermissions({ dispatch }, item) {
            dispatch('loading/setLoading', true, { root: true }); // Start loading
            try {
                await roleApiService.editManual(item.id, item.data);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                throw error;
            } finally {
                dispatch('loading/setLoading', false, { root: true }); // End loading
            }
        },
    }
});

const page = generateDynamicModule({
    moduleName: "page",
    resource: "pages",
    include: "include=author,tags",
    useApp: true,
});

const category = generateDynamicModule({
    moduleName: "category",
    resource: "categories",
    useApp: true,
});

const tag = generateDynamicModule({
    moduleName: "tag",
    resource: "tags",
    useApp: true,
});

const channel = generateDynamicModule({
    moduleName: "channel",
    resource: "channels",
    useApp: true,
});

const series = generateDynamicModule({
    moduleName: "series",
    resource: "series",
    useApp: true,
});

const contentType = generateDynamicModule({
    moduleName: "contentType",
    resource: "content-types",
    cached: true
});

const title = generateDynamicModule({
    moduleName: "title",
    include: "include=authors,channel,categories,rating,tags,chapters,series,content-type,recommendations,casts,recommendations.authors,recommendations.chapters",
    resource: "titles",
    useApp: true,
});

const region = generateDynamicModule({
    moduleName: "region",
    resource: "regions"
});

const titlePrice = generateDynamicModule({
    moduleName: "titlePrice",
    resource: "title-pricing"
});

const adProvider = generateDynamicModule({
    moduleName: "adProvider",
    resource: "ad-providers",
    useApp: true,
});

const rating = generateDynamicModule({
    moduleName: "rating",
    resource: "ratings",
    useApp: true,
});

const review = generateDynamicModule({
    moduleName: "review",
    resource: "reviews",
    useApp: true,
});

const transaction = generateDynamicModule({
    moduleName: "transaction",
    resource: "transactions"
});

const slider = generateDynamicModule({
    moduleName: "slider",
    resource: "sliders",
    useApp: true,
});

const app = generateDynamicModule({
    moduleName: "app",
    resource: "apps"
});

const appId = localStorage.getItem('app') ?? 1;

export default createStore({
    modules: {
        swal,
        loading,
        auth,
        author,
        profile,
        role,
        user,
        category,
        rating,
        tag,
        channel,
        series,
        contentType,
        title,
        appSetting,
        subscriptionPlan,
        adProvider,
        region,
        titlePrice,
        userPurchase,
        permission,
        transaction,
        page,
        review,
        slider,
        app,
        metrics,
        titleRecommendation,
        cast,
        customer
    },
    state: {
        appId: appId,
        hideConfigButton: false,
        isPinned: true,
        isRTL: false,
        color: "",
        sidebarType: "bg-white",
        darkMode: false,
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: false,
        showNavbar: false,
        showFooter: false,
        showMain: true,
        layout: "default",
        bootstrap,
    },
    mutations: {
        navbarMinimize(state) {
            const sidenav_show = document.querySelector("#app");

            if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
                sidenav_show.classList.add("g-sidenav-pinned");
                sidenav_show.classList.remove("g-sidenav-hidden");
                state.isPinned = true;
            } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
                sidenav_show.classList.add("g-sidenav-hidden");
                sidenav_show.classList.remove("g-sidenav-pinned");
                state.isPinned = false;
            } else if (sidenav_show.classList.contains("g-sidenav-show")) {
                sidenav_show.classList.add("g-sidenav-pinned");
                state.isPinned = true;
            }
        },
        setSidebarType(state, payload) {
            state.sidebarType = payload;
        },
        setApp(state, payload) {
            state.appId = payload;
            localStorage.setItem('app', payload)
        },
        navbarFixed(state) {
            state.isNavFixed = state.isNavFixed === false;
        },
        toggleDefaultLayout(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
        setLoggedInLayout(state, payload) {
            state.showNavbar = payload ?? false;
            state.showSidenav = payload ?? false;
            state.showFooter = payload ?? false;
        },
    },
    actions: {},
    getters: {
        getAppId: state => state.appId
    },
});
