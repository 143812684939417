<template>
    <UILoadingBar></UILoadingBar>
    <sidenav v-if="showSidenav"/>
    <main class="main-content position-relative max-height-vh-100 h-100">
        <navbar v-if="showNavbar" @refresh="$router.go(0)"/>
        <router-view/>
        <app-footer v-if="showFooter"/>
    </main>
</template>

<script setup>
import {computed, onMounted, nextTick} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

import Sidenav from '/@/elements/Sidenav';
import Navbar from '/@/elements/Navbar.vue';
import AppFooter from '/@/elements/Footer.vue';

// Vue 3 Composition API setup
const store = useStore();
const router = useRouter();
// Accessing state from Vuex store
const showSidenav = computed(() => store.state.showSidenav);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
// Start progress bar before each route navigation and finish after
onMounted(() => {
    router.beforeEach((to, from, next) => {
        //store.commit('loading/START_LOADING');
        nextTick(() => {
            next();
        });
    });
});
</script>
