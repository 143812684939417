import { generateDynamicModule } from "/@/helpers/dynamicModuleGenerator";

export const titleRecommendation = generateDynamicModule({
    moduleName: 'titleRecommendation',
    resource: 'titles',
    initialState: {
        selectedTitles: [],
        availableTitles: [],
        filters: {
            category: '',
            tag: '',
            query: '',
        },
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async fetchAvailableTitles({ commit, state }, filters) {
            console.log(state);
            const params = {
                filter: Object.entries({
                    category: filters.filter?.category,
                    tag: filters.filter?.tag,
                    name: filters.filter?.query,
                    status: 'published',
                }).reduce((acc, [key, value]) => {
                    if (value) {
                        acc[key] = value;
                    }
                    return acc;
                }, {}),
                page: {
                    size: 30
                }
            };
            await this.dispatch('title/getList', params);
        },
        addTitleToSelection({ commit }, title) {
            commit('ADD_TITLE_TO_SELECTION', title);
        },
        removeTitleFromSelection({ commit }, title) {
            commit('REMOVE_TITLE_FROM_SELECTION', title);
        },
        moveTitleUp({ commit }, index) {
            commit('MOVE_TITLE_UP', index);
        },
        moveTitleDown({ commit }, index) {
            commit('MOVE_TITLE_DOWN', index);
        },
    },
    mutations: {
        ADD_TITLE_TO_SELECTION(state, title) {
            state.selectedTitles.push(title);
        },
        REMOVE_TITLE_FROM_SELECTION(state, title) {
            state.selectedTitles = state.selectedTitles.filter(
                (item) => item.id !== title.id
            );
        },
        MOVE_TITLE_UP(state, index) {
            if (index > 0) {
                const temp = state.selectedTitles[index - 1];
                state.selectedTitles[index - 1] = state.selectedTitles[index];
                state.selectedTitles[index] = temp;
            }
        },
        MOVE_TITLE_DOWN(state, index) {
            if (index < state.selectedTitles.length - 1) {
                const temp = state.selectedTitles[index + 1];
                state.selectedTitles[index + 1] = state.selectedTitles[index];
                state.selectedTitles[index] = temp;
            }
        },
    },
    getters: {
        availableTitles: (state) => state.items,
        selectedTitles: (state) => state.selectedTitles,
    },
});
