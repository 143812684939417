<template>
    <div v-show="isLoading" class="loading-bar"/>
</template>

<script setup>
import {computed} from 'vue';
import {useStore} from 'vuex';

const store = useStore();
const isLoading = computed(() => store.state.loading.loading > 0);

</script>

<style>
.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: rgb(195, 0, 255);
    z-index: 999;
    /* Or any color you like */
    animation: loadingBarAnimation 2s infinite;
}

@keyframes loadingBarAnimation {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}
</style>
