<template>
    <div class="table-responsive">
        <table :id="tableRef"
               :ref="tableRef"
               class="table table-flush">
            <thead class="thead-light">
                <tr>
                    <th v-for="(header, key) in headers"
                        :key="key"
                        :title="header.title"
                        :data-sortable="header.sortable ?? true"
                    >
                        {{ header.text }}
                    </th>
                </tr>
            </thead>
            <tbody class="text-sm">
                <slot></slot> <!-- For custom row rendering -->
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "DataTable",
    props: {
        headers: {
            type: Array,
            default: () => [],
        },
        tableRef: {
            type: String,
            default: "dataList",
        },
    },
};
</script>
