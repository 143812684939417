<template>
    <aside id="sidenav-main"
           class="sidenav navbar navbar-vertical navbar-expand-xs border-0"
           :class="`bg-default fixed-start`">
        <div class="sidenav-header">
            <router-link class="m-0 px-3 py-3 navbar-brand d-flex"
                         to="/">
                <img :src="logoWhite"
                     class="navbar-brand-img h-100"
                     alt="main_logo"/>
                <span class="ms-2 font-weight-bold my-auto">
                    Platform
                </span>
            </router-link>
        </div>
        <hr class="mt-0 horizontal dark"/>
        <sidenav-list :navigation-groups="navigationGroups"/>
    </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logoWhite from "/@/assets/img/heard.png";
import { navigationGroups } from "/@/config";

export default {
    name: "UINavBase",
    components: {
        SidenavList
    },
    setup() {
        return {
            logoWhite,
            navigationGroups,
        };
    }
};
</script>
