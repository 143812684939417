import httpCaller from "./http";
import authHeader from "./auth-header";

const API_URL = import.meta.env.VITE_API_BASE_URL;

async function makeCall(options, metric, filter) {
    const payload = JSON.stringify({options});
    const response = await httpCaller.post(
        `${API_URL}/metrics/${metric}/${filter}`,
        payload,
        {
            headers: authHeader()
        }
    );
    return response.data;
}

export default {
    makeCall,
};
