<!-- TimeDateSelector.vue -->
<template>
    <div class="flex-container">
        <!-- Time -->
        <div class="flex-item mb-3">
            <label class="form-label">Time</label>
            <select v-model="localTime" class="form-select" @change="updatedDateFields">
                <option value="all_time"> All time </option>
                <option value="year"> Year </option>
                <option value="year_to_year"> Year to Year </option>
                <option value="month_to_month"> Month to Month </option>
                <option value="month"> Month </option>
            </select>
        </div>
        <!-- Date Input -->
        <div v-if="localTime !== 'all_time'">
            <div v-if="localTime === 'year'">
                <div class="flex-item mb-3">
                    <label class="form-label">Year</label>
                    <select v-model="startDate" class="form-select">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div v-if="localTime === 'year_to_year'">
                <div class="flex-container">
                    <div class="flex-item mb-3">
                        <label class="form-label">From Year</label>
                        <select v-model="startDate" class="form-select">
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="flex-item mb-3">
                        <label class="form-label">To Year</label>
                        <select v-model="endDate" class="form-select">
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div v-if="localTime === 'month_to_month'">
                <div class="flex-container">
                    <div class="flex-item mb-3">
                        <label class="form-label">From</label>
                        <input v-model="startDate" type="month" class="form-control" />
                    </div>
                    <div class="flex-item mb-3">
                        <label class="form-label">To</label>
                        <input v-model="endDate" type="month" class="form-control" />
                    </div>
                </div>
            </div>
            <div v-if="localTime === 'month'" class="flex-item mb-3">
                <label class="form-label">Month</label>
                <input v-model="startDate" type="month" class="form-control" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        time: {
            type: String,
            required: true
        },
        years: {
            type: Array,
            required: true
        },
    },
    emits: ['updateTime', 'updateStartDate', 'updateEndDate'],
    data() {
        return {
            localTime: this.time,
            startDate: '',
            endDate: ''
        };
    },
    watch: {
        localTime(newVal) {
            this.$emit('updateTime', newVal);
        },
        startDate(newVal) {
            this.$emit('updateStartDate', newVal);
        },
        endDate(newVal) {
            this.$emit('updateEndDate', newVal);
        }
    },
    methods: {
        updateDateFields() {
            this.startDate = '';
            this.endDate = '';
        }
    }
};
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.flex-item {
    flex: 1; 
    min-width: 150px; 
    max-width: 150px;
}
</style>
