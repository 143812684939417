<template>
	<div class="card mb-3">
		<div class="card-body p-3">
				<div class="row gx-4">
						<div class="col-auto">
								<div class="d-flex avatar avatar-xl position-relative">
										<img :src="profileImage()"
												:alt="customer.name"
												class="w-100 border-radius-lg"
										/>
								</div>
						</div>
						<div class="col-auto my-auto">
								<div class="h-100">
										<h5 class="mb-1">{{ customer.name }}</h5>
										<p class="mb-0 font-weight-bold text-sm">{{ customer.email }}</p>
								</div>
						</div>
						<div
										class="mx-auto mt-3 col-lg-5 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
						>
								<div class="nav-wrapper position-relative end-0">
										<ul class="p-1 bg-transparent nav nav-pills nav-fill"
												role="tablist"
										>
												<li class="nav-item">
														<a class="px-0 py-1 mb-0 nav-link active"
																data-bs-toggle="tab"
																href="javascript:;"
																role="tab"
																aria-selected="true"
														>
															<i class="fa fa-tablet-alt me-1"></i>
																<span class="ms-1">Profile</span>
														</a>
												</li>
												<li class="nav-item">
														<a class="px-0 py-1 mb-0 nav-link"
																data-bs-toggle="tab"
																href="javascript:;"
																role="tab"
																aria-selected="false"
														>
															<i class="fa fa-list me-1"></i>
																<span class="ms-1">Transactions</span>
														</a>
												</li>
												<li class="nav-item">
														<a class="px-0 py-1 mb-0 nav-link"
																data-bs-toggle="tab"
																href="javascript:;"
																role="tab"
																aria-selected="false"
														>
															<i class="fa fa-envelope me-1"></i>
																<span class="ms-1">Messages</span>
														</a>
												</li>
												<li class="nav-item">
														<a class="px-0 py-1 mb-0 nav-link"
																data-bs-toggle="tab"
																href="javascript:;"
																role="tab"
																aria-selected="false">
															<i class="fa fa-history me-1"></i>
																<span class="ms-1">Activity</span>
														</a>
												</li>
										</ul>
								</div>
						</div>
				</div>
		</div>
	</div>
</template>

<script>
import placeholder from "/@/assets/img/placeholder.jpg";

export default {
    name: "UICustomerHeader",
    props: {
        customer: {
            type: Object,
            required: true
        }
    },
    methods: {
        profileImage() {
            return this.customer.profile_image
                ? this.customer.profile_image
                : placeholder;
        },
    }
}
</script>
