<!-- FilterResolver.vue -->
<template>
    <component :is="currentComponent" @update-data="emitData"/>
</template>

<script>
import GetSales from './GetSales.vue';
import TitleCount from './TitleCount.vue';
import TitleSales from './TitleSales.vue';
import BestSellers from './BestSellers.vue';

export default {
    components: {
        GetSales,
        TitleCount,
        TitleSales,
        BestSellers
    },
    props: {
        selection: {
            type: String,
            required: true
        }
    },
    computed: {
        currentComponent() {
            switch (this.selection) {
            case 'get_sales':
                return GetSales;
            case 'title_count':
                return TitleCount;
            case 'title_sales':
                return TitleSales;
            case 'title_best_sellers':
                return BestSellers;
            default:
                return null;
            }
        }
    },
    methods: {
        emitData(data) {
            this.$emit('update-data', data);
        }
    }
};
</script>
