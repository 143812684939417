import axios from 'axios';
import store from '/@/store';

const httpCaller = axios.create();

httpCaller.interceptors.request.use(config => {
    store.commit('loading/START_LOADING');
    return config;
}, error => {
    store.commit('loading/FINISH_LOADING');
    return Promise.reject(error);
});

httpCaller.interceptors.response.use(response => {
    store.commit('loading/FINISH_LOADING');
    return response;
}, error => {
    store.commit('loading/FINISH_LOADING');
    return Promise.reject(error);
});

export default httpCaller;