<template>
    <UIListLayout>
        <template #header>
            <UIDetailHeader :namespace="storeNamespace"
                            :show-add-button="showAddButton"
                            @refresh="refreshTable()">
            </UIDetailHeader>
        </template>

        <template #filters>
            <UIListFilterBar
                :filters="filters"
                :per-page="perPage"
                :record-counts="recordsCounts"
                @update:filters="updateFilters"
                @update:per-page="updatePerPage"
            />
        </template>

        <template #table>
            <div class="table-responsive">
                <table :ref="`${namespace}dataList`"
                       class="table table-flush">
                    <thead class="thead-light">
                    <tr>
                        <UIListDataTableBase :name="storeNamespace"
                                             :headers="headers"/>
                    </tr>
                    </thead>
                    <tbody class="text-sm">
                    <!-- Dynamic rows here -->
                    </tbody>
                </table>
            </div>
        </template>

        <template v-if="metaPage" #pagination>
            <UIPaginationSummary :pagination="pagination" :meta-page="metaPage"/>
            <UIBasePagination v-model="pagination.currentPage"
                              class="pagination-primary pagination-md mb-0"
                              :value="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total"
                              @click="handlePageChange($event)"/>
        </template>
    </UIListLayout>
</template>

<script>
import UIBasePagination from "/@/elements/UIBasePagination.vue";
import UIDetailHeader from "/@/components/UIDetailHeader.vue";
import UIListLayout from "/@/components/layout/UIListLayout.vue";
import UIPaginationSummary from "/@/elements/UIPaginationSummary.vue";
import UIListDataTableBase from "/@/components/UIListDataTableBase.vue";
import UIListFilterBar from "/@/components/UIListFilterBar.vue";
import eventTable from "/@/mixins/eventTable";
import dataTable from "/@/mixins/dataTable";
import FormatColumn from "/@/helpers/formatColumn";
import { useRoute, useRouter } from 'vue-router';

export default {
    name: "ListPage",
    components: {
        UIDetailHeader,
        UIListLayout,
        UIPaginationSummary,
        UIBasePagination,
        UIListDataTableBase,
        UIListFilterBar
    },
    mixins: [eventTable, dataTable],
    props: {
        namespace: {
            type: String,
            default: () => '',
        },
        headers: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Array,
            default: () => [
                {
                    type: 'search',
                    name: 'name',
                    value: ''
                }
            ],
        },
        sortDirection: {
            type: String,
            default: () => '-id',
        },
        showAddButton: {
            type: Boolean,
            default: () => true,
        },
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        return { route, router };
    },
    data() {
        return {
            search: '',
            dataTable: null,
            initialFilters: {},
            perPage: 25,
            recordsCounts: [10, 25, 50, 100], // Add this line
        }
    },
    computed: {
        storeNamespace() {
            return this.namespace;
        },
        dataList() {
            return this.$store.getters[`${this.storeNamespace}/list`]?.data;
        },
        metaPage() {
            return this.$store.getters[`${this.storeNamespace}/list`]?.meta ?? {};
        },
        paginated() {
            return this.$store.getters[`${this.storeNamespace}/list`]?.params ?? [];
        },
        // Transform dataItems for table display
        dataItems() {
            return this.dataList?.map(row => (this.formattedRows(row))) ?? [];
        },
    },
    watch: {
        metaPage: {
            handler(newVal) {
                this.reactivePagination(newVal);
            },
            immediate: false,
            deep: true,
        },
        dataList: {
            handler(newVal) {
                this.reactiveTable(newVal);
            },
            immediate: false,
            deep: true,
        },
    },
    async mounted() {
        await this.initializeDataTable(`${this.namespace}dataList`, {});
    },
    methods: {
        formattedRows(row) {
            return FormatColumn.prepareFormattedRows(row, this.headers)
        },
        async updateFilters(filters) {
            this.additionalFilters = {};
            filters.forEach(filter => {
                if (filter.type === 'search') {
                    this.searchQuery = filter.value;
                } else if (filter.value) {
                    this.additionalFilters[filter.name] = filter.value;
                }
            });
            this.currentPage = 1;
            await this.fetchData();
        },
        
        async updatePerPage(perPage) {
            this.perPage = perPage;
            this.currentPage = 1;
            await this.fetchData();
        },
        
        async reactiveTable() {
            // console.log(this.dataList);
            if (this.dataList !== undefined) {
                if (this.dataList.length > 0) {
                    this.dataTable.data = [];
                    this.dataTable.refresh();
                    this.dataTable.insert({data: this.dataItems});
                    this.removeEvent();
                    this.eventToCall({
                        table: this.dataTable,
                        namespace: this.storeNamespace
                    });
                } else {
                    this.dataTable.setMessage('No results match your search query');
                }
            }
            this.updateQueryParams();
        },
        async handlePageChange(page) {
            this.currentPage = page;
            await this.fetchData();
        },
    }
}
</script>
