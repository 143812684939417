import {createCrudRoutes} from "/@/helpers/createCrudRoutes";

const series = (components) => createCrudRoutes("series", "/content/series", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            sortDirection: '-id',
            headers: [
                {key: 'name', title: 'Name', format: 'bold'},
                {key: 'createdAt', title: 'Created At', format: 'date'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
        },
        add: {
            model: {
                type: 'series',
                name: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                    ]
                ],
            }
        },
        edit: {
            layout: 'default',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                    ]
                ],
            }
        }
    }
});

export {
    series
}