import debounce from "lodash.debounce";
import store from "/@/store";

/**
 * Debounced async function to dispatch a getResourceList action.
 * Fetches a paginated, filtered and sorted list of resources from the store.
 * @param {string} namespace - The namespace for the getResourceList action.
 * @param {Object} params - Params object with pagination, filter and sort options.
 */
const getResourceList = debounce(async function (namespace, params) {
    await store.dispatch(namespace, {
        ...(params.sort ? {sort: params.sort} : {}),
        filter: {
            ...(params.query ? {name: params.query} : {}),
            ...(params.name ? {name: params.name} : {}),
            ...(params.authorName ? {author_name: params.authorName} : {}),
            ...(params.title ? {title: params.title} : {}),
            ...(params.tag ? {tag: params.tag} : {}),
            ...(params.category ? {category: params.category} : {}),
            ...(params.status ? {status: params.status} : {}),
            ...params.other ?? {},
        },
        page: {
            number: params.nr,
            size: params.perpage,
        },
    });
}, 300);

export {
    getResourceList
}