<template>
    <UIBaseLayout>
        <form role="form" @submit.prevent="handleRequest()">
            <!-- Title Card -->
            <div class="card mb-3">
                <div class="pb-0 card-header p-3">
                    <div class="d-lg-flex align-items-center">
                        <div>
                            <h1 class="h4 letter-wider mb-0">
                                User Reports
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0 pb-3"></div>
            </div>
            <!-- Input fields Card -->
            <div class="card mb-4">
                <div class="card-body">
                    <label>Report Options</label> <hr>
                    <div class="flex-container">
                        <!-- Filter selection -->
                        <div class="flex-item mb-3">
                            <label class="form-label">Model</label>
                            <select class="form-select" v-model="selectedFilter">
                                <option value="active">Active Users</option>
                                <option value="inactive">Inactive Users</option>
                                <option value="subscribed">Subscribed</option>
                            </select>
                        </div>
                        <!-- App ID -->
                        <div v-if="admin" class="flex-item mb-3">
                            <label class="form-label">App ID</label>
                            <div>
                                <div class="checkbox-group">
                                    <div class="checkbox-item" v-for="app in appList" :key="app.id">
                                        <input type="checkbox" :id="app.name.toLowerCase()" :value="app.id" v-model="appId" />
                                        <label :for="app.name.toLowerCase()">{{ app.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Time and Date Input -->
                        <div class="flex-item">
                            <TimeDateSelector
                                :time="time"
                                :years="years"
                                :start_date="start_date"
                                :end_date="end_date"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Extra input Cards  -->
            <div v-if="selectedFilter != ''" class="flex-container">
                <FilterResolver :selection="selectedFilter" @update-data="handleUpdateData" />
            </div>
            
            <!-- Generate report button -->
            <div v-if="!showResults" class="text-center mb-3">
                <argon-button size="sm" @click="handleRequest">
                    <i class="fa fa-pencil me-1"/>
                        Generate Report
                </argon-button>
            </div>

        </form>

        <!-- Results Card -->
        <div v-if="showResults">
            <!-- Graph -->
            <div class="card mb-4">
                <div class="card-body">
                </div>
            </div>
            <!-- Table -->
            <div class="card mb-4">
                <div class="card-body">
                    <argon-button size="sm" @click="showResults = false">Clear</argon-button>
                    <argon-button size="sm" class="float-end ms-3" @click="exportResults = false">Export</argon-button>
                    <hr>
                    <DataTable :headers="tableHeaders" :tableRef="'usersTable'">
                        <tr v-for="(entry, index) in usersData" :key="index" class="data-row">
                            <td v-for="(value, key) in entry" :key="key">{{ value }}</td>
                        </tr>
                    </DataTable>
                </div>
            </div>
        </div>
    </UIBaseLayout>
</template>

<script>
import UIBaseLayout from "/@/components/layout/UIBaseLayout.vue";
import FilterResolver from "../Users/Components/UserFilterResolver.vue";
import DataTable from "/@/components/layout/UIDataTableLayout.vue";
import { mapGetters, mapActions } from 'vuex';
import TimeDateSelector from "../Components/TimeDateSelector.vue";

export default {
    name: "UsersReportPage",
    components: { UIBaseLayout, FilterResolver, DataTable, TimeDateSelector },
    data() {
        return {
            appId: [],
            time: 'all_time',
            start_date: '',
            end_date: '',
            years: this.generateYears(),
            genBarGraph: false,
            genLineGraph: false,
            selectedFilter: '',
            showResults: false,
            admin: false,
            debug: false,
        };
    },
    computed: {
        ...mapGetters('metrics', {
            users: 'getMetric'
        }),
        ...mapGetters(['getAppId']),
        usersData() {
            return this.users('users', this.selectedFilter);
        },
        appList() {
            return this.$store.getters["app/list"]?.data;
        },
        tableHeaders() {
            if (this.usersData.length > 0) {
                return Object.keys(this.usersData[0]).map(key => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key
                }));
            }
            return [];
        },
    },
    watch: {
        start_date(newVal){
            if(this.time === 'year') {
                this.end_date = newVal;
            }
        }
    },
    created() {
        this.appId = [this.getAppId];
    },
    async mounted() {
        await this.$store.dispatch("app/getList");
    },
    methods: {
        ...mapActions('metrics', {
            fetchMetric: 'fetchMetric'
        }),
        generateYears() {
            const currentYear = new Date().getFullYear();
            const startYear = 2024;
            let years = [];
            for (let year = startYear; year <= currentYear; year++) {
                years.push(year);
            }
            return years;
        },
        updateDateFields() {
            this.start_date = '';
            this.end_date = '';
        },
        async handleRequest() {

            const options = {
                app_id: this.appId,
                resolution: this.time,
                start_date: this.start_date,
                end_date: this.end_date,
            };

            //Debug
            if (this.debug) {console.log(this.genBarGraph, this.genLineGraph)};

            await this.fetchMetric({ options, metric: 'users', filter: this.selectedFilter });
            this.showResults = true;
        }
    }
};
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Adjust the gap between items as needed */
}

.flex-item {
    flex: 1; /* Adjust the flex value as needed */
    min-width: 100px; /* Adjust the minimum width as needed */
    max-width: 200px;
}
</style>
