/* eslint-disable no-unused-vars */
export const loading = {
    namespaced: true,
    state: {
        isLoading: false,
        loading: 0,
    },
    getters: {
        isLoading: state => state.isLoading,
        loading: state => state.loading,
    },
    mutations: {
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        START_LOADING(state) {
            state.loading++;
        },
        FINISH_LOADING(state) {
            state.loading--;
        }
    },
    actions: {
        setLoading({commit}, isLoading) {
            commit('SET_LOADING', isLoading);
        },
        startLoading({commit}, isLoading) {
            commit('START_LOADING');
        },
        finishLoading({commit}, isLoading) {
            commit('FINISH_LOADING');
        }

    },
};