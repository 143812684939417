import metricsService from "/@/services/metrics.service.js";

export const metrics = {
    namespaced: true,
    state: {
        metricsData: {},
    },
    actions: {
        async fetchMetric({ commit }, { options, metric, filter }) {
            const data = await metricsService.makeCall(options, metric, filter);
            commit('SET_METRIC', { metric, filter, data });
        }
    },
    mutations: {
        SET_METRIC(state, { metric, filter, data }) {
            if (!state.metricsData[metric]) {
                state.metricsData[metric] = {};
            }
            state.metricsData[metric][filter] = data;
        }
    },
    getters: {
        getMetric: (state) => (metric, filter) => {
            return state.metricsData[metric] ? state.metricsData[metric][filter] : [];
        }
    }
};