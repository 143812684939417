import { createRouter, createWebHistory } from "vue-router";
const Error404 = () => import("/@/views/error/Error404.vue");
const Error500 = () => import("/@/views/error/Error500.vue");
// Insights
import CustomerOverview from "/@/views/admin/Customer/Insights/Overview.vue";
import CustomerListPage from "/@/views/admin/Customer/List.vue";
import CustomerProfilePage from "/@/views/admin/Customer/Profile/Overview.vue";
import MarketingAnalytics from "/@/views/admin/Marketing/Analytics/Analytics.vue";
import SalesAnalytics from "/@/views/admin/Sales/Insights/Overview.vue";
import MetricsOverview from "/@/views/admin/Metrics/Overview.vue";
import SalesReport from "/@/views/admin/Metrics/Sales/Pages/SalesReportPage.vue";
import UsersReport from "/@/views/admin/Metrics/Users/UsersReportPage.vue";

import { createCrudRoutes } from "/@/helpers/createCrudRoutes";
import {
    adProvider,
    author,
    appSetting,
    app,
    tag,
    page,
    channel,
    campaign,
    transaction,
    title,
    rating,
    category,
    subscriptionPlan,
    user,
    review,
    region,
    series,
    contentType,
    cast,
    customer
} from "/@/router/routes";
import store from "/@/store";

// Explicit imports for lazy paths, this is needed for bundler to be able to build correctly
const Login = () => import("/@/views/auth/Login.vue");
const Register = () => import("/@/views/auth/Register.vue");
const ForgotPassword = () => import("/@/views/auth/ForgotPassword.vue");
const ResetPassword = () => import("/@/views/auth/ResetPassword.vue");

const ListPage = () => import("/@/views/base/List.vue");
const AddPage = () => import("/@/views/base/Add.vue");
const EditPage = () => import("/@/views/base/Edit.vue");
const CustomerViewPage = () => import("/@/views/admin/Customer/View.vue");
const Dashboard = () => import("/@/views/admin/Dashboard.vue");
const UserProfile = () => import("/@/views/admin/UserProfile/EditUserProfile.vue");
const RoleEdit = () => import("/@/views/admin/Role/Edit.vue");
const TitleEdit = () => import("/@/views/admin/Title/Edit.vue");
const SliderEdit = () => import("/@/views/admin/SlidersAndBanner/Edit.vue");

const routes = [
    {
        path: "/",
        name: "/",
        redirect: "/dashboard",
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            auth: true,
            pageTitle: "Dashboard"
        },
    },
    {
        path: "/users/profile",
        name: "user.profile",
        component: UserProfile,
        meta: {
            auth: true,
            pageTitle: "User Profile"
        },
    },
    ...adProvider({
        list: ListPage,
        add: AddPage,
        edit: EditPage,
    }),
    ...app({
        list: ListPage,
        add: AddPage,
        edit: EditPage,
    }),
    ...appSetting({
        list: ListPage,
        add: AddPage,
        edit: EditPage,
    }),
    ...author({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...campaign({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...category({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...channel({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...rating({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...series({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...cast({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...contentType({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...region({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...review({
        list: ListPage,
    }),
    ...createCrudRoutes("role", "/users/roles", {
        roles: ['admin'],
        components: {
            list: ListPage,
            add: AddPage,
            edit: RoleEdit
        },
        listProps: {
            list: {
                headers: [
                    { key: 'name', title: 'Name', format: 'bold' },
                    { key: 'createdAt', title: 'Created At', format: 'date' },
                    { key: 'action', title: 'Action', format: 'actions', sortable: false }
                ],
            },
            add: {
                model: {
                    type: "roles",
                    name: "",
                },
                layout: 'default',
                formFields: {
                    main: [
                        [
                            { key: 'name', label: 'Name', type: 'text' },
                        ]
                    ],
                }
            }
        },
    }),
    ...subscriptionPlan({
        list: ListPage,
        add: AddPage,
        edit: EditPage
    }),
    ...page({
        list: ListPage,
        add: AddPage,
        edit: EditPage,
    }),
    ...tag({
        list: ListPage,
        add: AddPage,
        edit: EditPage,
    }),
    ...title({
        list: ListPage,
        add: AddPage,
        edit: TitleEdit
    }),
    ...transaction({
        list: ListPage,
    }),
    ...user({
        list: ListPage,
        add: AddPage,
        edit: EditPage,
    }),
    ...customer({
        list: ListPage,
        edit: CustomerViewPage,
    }),
    ...createCrudRoutes("userPurchase", "/sales/user-purchases", {
        roles: ['admin'],
        components: {
            list: ListPage,
        },
        cruds: ["list"],
        listProps: {
            list: {
                showAddButton: false,
                headers: [
                    { key: 'user', title: 'User', format: 'relation' },
                    { key: 'title', title: 'Title', format: 'relation' },
                    { key: 'transaction_id', title: 'Transaction' },
                    { key: 'createdAt', title: 'Date', format: 'date' },
                    // { key: 'action', title: 'Action', format: 'actions', sortable: false }
                ],
            }
        },
    }),
    ...createCrudRoutes("slider", "/content/sliders-and-banners", {
        roles: ['staff', 'admin'],
        components: {
            list: ListPage,
            add: AddPage,
            edit: SliderEdit,
        },
        listProps: {
            list: {
                headers: [
                    { key: 'name', title: 'Title', format: 'bold' },
                    { key: 'location', title: 'Location', format: 'inlineCode' },
                    { key: 'createdAt', title: 'Created At', format: 'date' },
                    { key: 'status', title: 'Status', format: 'status' },
                    { key: 'action', title: 'Action', format: 'actions', sortable: false }
                ],
            },
            add: {
                model: {
                    type: 'sliders',
                    name: "",
                    location: "",
                    slides: '[]',
                    config: '{}',
                    status: 'inactive',
                },
                layout: 'side',
                formFields: {
                    main: [
                        [
                            { key: 'name', label: 'Name', type: 'text' },
                            { key: 'location', label: 'Location', type: 'text' },
                            { key: 'config', label: 'Config', type: 'codemirror' },
                        ]
                    ],
                    side: [
                        [
                            { key: 'status', label: '', type: 'status', statusType: 'simple', class: 'mb-0' },
                        ]
                    ]
                }
            }
        },
    }),
    {
        path: "/metrics/overview",
        name: "metrics.overview",
        component: MetricsOverview,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Reports"
        }
    },
    {
        path: "/metrics/sales",
        name: "metrics.sales",
        component: SalesReport,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Sales Report"
        }
    },
    {
        path: "/metrics/users",
        name: "metrics.users",
        component: UsersReport,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Users Report"
        }
    },
    {
        path: "/insights/marketing",
        name: "insights.marketing",
        component: MarketingAnalytics,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Marketing Analytics"
        },
    },
    {
        path: "/insights/revenue",
        name: "insights.revenue",
        component: SalesAnalytics,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Sales Analytics"
        },
    },
    {
        path: "/insights/customers",
        name: "insights.customer",
        component: CustomerOverview,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Customer Insights"
        },
    },
    {
        path: "/insights/customers/list",
        name: "insights.customer.list",
        component: CustomerListPage,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Customers"
        },
    },
    {
        path: "/insights/customers/profile/:id",
        name: "insights.customer.profile",
        component: CustomerProfilePage,
        meta: {
            auth: true,
            roles: ['admin', 'creator'],
            pageTitle: "Customer Profile"
        },
    },
    /*
      {
          path: "/pages/pricing-page",
          name: "page.pricing",
          component: Pricing,
          meta: { middleware: guest, pageTitle: "Pricing Page" },
      },
      */
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            auth: false,
            pageTitle: "Login"
        },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            auth: false,
            pageTitle: "Register"
        },
    },
    {
        path: "/password/forgot",
        name: "forgot.password",
        component: ForgotPassword,
        meta: {
            auth: false,
            pageTitle: "Forgot Password"
        },
    },
    {
        path: "/password/reset",
        name: "reset.password",
        component: ResetPassword,
        meta: {
            auth: false,
            pageTitle: "Reset Password"
        },
    },
    {
        path: "/notfound",
        name: "error.404",
        component: Error404,
        meta: {
            pageTitle: "Error 404"
        },
    },
    {
        path: "/error",
        name: "error.500",
        component: Error500,
        meta: {
            pageTitle: "Error 500"
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    parseQuery(query) {
        return Object.fromEntries(new URLSearchParams(query));
    },
    stringifyQuery(query) {
        const params = new URLSearchParams();
        for (const key in query) {
            if (query[key] !== undefined && query[key] !== null) {
                params.append(key, query[key]);
            }
        }
        return params.toString();
    },
});

router.beforeEach(async (to, from, next) => {
    const isAuthenticated = await store.getters["auth/isAuthenticated"];
    console.log('isAuthenticated', isAuthenticated);
    const me = await store.getters["profile/me"];
    if (isAuthenticated && me === null) {
        await store.dispatch("profile/me");
    }
    const userRoles = await me?.roles.map(role => role.name) || [];
    if (to.meta.auth && !isAuthenticated) {
        // console.log('to.meta.auth', to.meta.auth && !isAuthenticated);
        // If the route requires authentication and the user is not authenticated
        return next({ name: 'login' });
    }

    if (!to.meta.auth && isAuthenticated) {
        // console.log('isAuthenticated', to.meta.auth, isAuthenticated);
        // If the route is for guests and the user is authenticated
        return next({ name: 'dashboard' });
    }

    if (me && to.meta.roles && to.meta.roles.length > 0) {
        // If the route requires specific roles
        const hasRequiredRole = await to.meta.roles.some(
            role => userRoles.includes(role)
        );
        // console.log('hasRequiredRole', to.meta.roles, userRoles, hasRequiredRole);
        if (!hasRequiredRole) {
            return next({ name: 'dashboard' });
        }
    }

    // If the user is authenticated and the route doesn't require specific roles
    if (isAuthenticated && !store.state.loggedInLayout) {
        await store.commit('setLoggedInLayout', true);
    }

    return next();
});

export default router;