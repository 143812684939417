<template>
    <div class="py-3">
        <div class="d-lg-flex align-items-center">
            <div>
                <h1 class="h4 letter-wider ps-3 mb-0">
                    {{ $t(`labels.pages.${namespace}.${title}`) }}
                </h1>
            </div>
            <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto d-flex align-items-center">
                    <div class="p-1 pe-3">
                        <div v-show="isLoading"
                             class="spinner-border text-muted spinner-border-sm"
                             role="status">
                            <span class="sr-only">
                                Loading...
                            </span>
                        </div>
                    </div>

                    <router-link v-if="returnRouteName.length > 0"
                                 :to="{ name: returnRouteName }"
                                 class="mb-0 btn bg-gradient-dark btn-sm">
                        <i class="fa fa-chevron-left me-1"/>
                        {{ $t("labels.common.backToList") }}
                    </router-link>

                    <!-- Conditional rendering for add button -->
                    <router-link v-if="showAddButton"
                                 :to="{ name: `${namespace}.add` }"
                                 class="mb-0 btn bg-gradient-primary btn-sm">
                        <i class="fa fa-plus me-1"/>
                        {{ $t(`labels.pages.${namespace}.add`) }}
                    </router-link>

                    <!-- Conditional rendering for refresh button -->
                    <argon-button v-if="showRefreshButton"
                                  class="float-end ms-3"
                                  color="dark"
                                  variant="gradient"
                                  size="sm"
                                  @click="emitRefreshEvent">
                        <i class="fa fa-refresh me-1"/>
                        {{ $t("labels.common.refresh") }}
                    </argon-button>

                    <argon-button v-if="showFlushRefreshButton"
                                  class="float-end ms-3"
                                  color="dark"
                                  variant="gradient"
                                  size="sm"
                                  @click="emitFlushRefreshEvent">
                        <i class="fa fa-refresh me-1"/>
                        {{ $t("labels.common.refresh") }}
                    </argon-button>
                    <slot name="buttons"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UIDetailHeader',
    props: {
        namespace: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: "list"
        },
        returnRouteName: {
            type: String,
            default: ""
        },
        showAddButton: {
            type: Boolean,
            default: true
        },
        showRefreshButton: {
            type: Boolean,
            default: true
        },
        showFlushRefreshButton: {
            type: Boolean,
            default: false
        }
    },
    emits: ['refresh'], // Define the event here
    computed: {
        isLoading() {
            return this.$store.getters['loading/loading'] > 0;
        }
    },
    methods: {
        emitRefreshEvent() {
            // Emit the event for the parent component to handle
            this.$emit('refresh');
        },
        emitFlushRefreshEvent() {
            // Emit the event for the parent component to handle
            this.flushLocalStorageCache();
            // this.$emit('refresh');
            this.refreshCurrentRoute();
        },
        flushLocalStorageCache() {
            const pattern = /^\d+\/.*\/getList$/;
            Object.keys(localStorage).forEach(key => {
                if (pattern.test(key)) {
                    localStorage.removeItem(key);
                }
            });
        },
        refreshCurrentRoute() {
            this.$router.replace({ 
                name: this.$route.name, 
                params: { ...this.$route.params },
                query: { ...this.$route.query, _t: Date.now() }
            });
        }
    }
}
</script>
