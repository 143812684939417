<template>
    <!-- Payment type -->
    <div class="flex-container">
        <div class="flex-item">
        <label class="form-label">Type</label>
            <div class="checkbox-group">
                <div class="checkbox-item">
                    <input type="checkbox" id="purchase" v-model="purchase" @change="emitData" />
                    <label for="purchase">Purchase</label>
                </div>
                <div class="checkbox-item">
                    <input type="checkbox" id="subscription" v-model="subscription" @change="emitData"/>
                    <label for="subscription">Subscription</label>
                </div>
            </div>
        </div>
        <GraphOptions class="flex-item" @update-data="handleUpdateData"></GraphOptions>
    </div>
</template>

<script>
import GraphOptions from '../../Components/GraphOptions.vue';

export default {
    components: {
        GraphOptions,
    },
    emits: ['update-data'],
    data() {
        return {
            purchase: false,
            subscription: false,
            genBarGraph: false,
            genLineGraph: false,
            genPieChart: false,
        };
    },
    methods: {
        handleUpdateData(data) {
            if (data.genBarGraph !== undefined) {
                this.genBarGraph = data.genBarGraph;
            }
            if (data.genLineGraph !== undefined) {
                this.genLineGraph = data.genLineGraph;
            }
            if (data.genPieChart !== undefined) {
                this.genPieChart = data.genPieChart;
            }
            this.emitData();
        },
        emitData() {
            this.$emit('update-data', { 
                purchase: this.purchase, 
                subscription: this.subscription, 
                genBarGraph: this.genBarGraph,
                genLineGraph: this.genLineGraph,
                genPieChart: this.genPieChart,
            });
        }
    }
};
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.flex-item {
    flex: 1; 
    min-width: 100px; 
    max-width: 120px;
}
</style>