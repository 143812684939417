/* eslint-disable no-unused-vars */
import router from "/@/router"
import Swal from "sweetalert2";
import store from "/@/store";

var deleteState = false;
var paramsCall = {
    sort: "",
    query: "",
    nr: "1",
    perpage: "5"
};

function showSwalDelete(id, table, deletePath, getPath, textDelete) {
    return store.dispatch('swal/showConfirmationDelete').then(async (result) => {
        if (result.isConfirmed) {
            try {
                deleteState = true;
                await store.dispatch(deletePath, id);
                await store.dispatch(getPath, {
                    ...(paramsCall.sort ? { sort: paramsCall.sort } : {}),
                    filter: {
                        ...(paramsCall.query ? { name: paramsCall.query } : {}),
                    },
                    page: {
                        number: paramsCall.nr,
                        size: paramsCall.perpage,
                    },
                });
                await store.dispatch('swal/show', {
                    type: "success",
                    message: textDelete
                });
            } catch (error) {
                await store.dispatch('swal/show', {
                    type: "error",
                    message: (error.response.data != null && true) ? error.response.data.errors[0].title : "Oops, something went wrong!",
                    width: (error.response.data != null && true) ? error.response.data.errors[0].title.length > 37 ? 360 : 300 : 300
                });
            }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.dismiss;
        }
    });
}

export default {
    methods: {
        eventToCall(options) {
            if (deleteState) {
                deleteState = false;
            }
            var buttons = document.querySelectorAll('.actionButton');

            const textDelete = options.textDelete ?? "Record deleted successfully!";
            const getPath = options.getPath ?? `${options.namespace}/getList`;
            const deletePath = options.deletePath ?? `${options.namespace}/delete`;
            const redirectPath = options.redirectPath ?? `${options.namespace}.edit`;

            buttons.forEach(function (button) {
                if (button.className.includes('deleteButton')) {
                    button.addEventListener('click', function () {
                        showSwalDelete(this.id, options.table, deletePath, getPath, textDelete);
                    })
                } else {
                    button.addEventListener('click', function () {
                        if (redirectPath === "user.edit") {
                            router.push({ name: redirectPath, params: { id: this.id } });
                        } else {
                            router.push({ name: redirectPath, params: { id: this.id } });
                        }
                    })
                }
            });
        },

        removeEvent() {
            const buttons = this.$el.querySelectorAll('.actionButton');
            buttons.forEach(function (button) {
                button.replaceWith(button.cloneNode(true));
            });
        }
    }
}