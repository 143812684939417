import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const category = (components) => createCrudRoutes("category", "/content/categories", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            headers: [
                { key: 'name', title: 'Name', format: 'titleWithCover' },
                { key: 'description', title: 'Description' },
                { key: 'titles_published', title: 'Linked titles' },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'status', title: 'Status', format: 'status' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'categories',
                name: '',
                description: '',
                parent_category_id: '',
                status: 'draft',
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'parent_category_id', label: 'Parent Category', type: 'select-dynamic', source: 'category', sourceType: 'categories', multiple: false, single: true },
                        { key: 'bisac', label: 'BISAC', type: 'text', class: 'col-md-6' },
                        { key: 'gardners_id', label: 'Gardners Ref', type: 'text', class: 'col-md-6' },
                        { key: 'description', label: 'Description', type: 'html' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: '', type: 'status', statusType: 'default' },
                    ],
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text' },
                        { key: 'parent_category_id', label: 'Parent Category', type: 'select-dynamic', source: 'category', sourceType: 'categories', multiple: false, single: true },
                        { key: 'bisac', label: 'BISAC', type: 'text', class: 'col-md-6' },
                        { key: 'gardners_id', label: 'Gardners Ref', type: 'text', class: 'col-md-6' },
                        { key: 'description', label: 'Description', type: 'html' },
                    ]
                ],
                side: [
                    [
                        { key: 'status', label: '', type: 'status', statusType: 'default' },
                    ],
                    [
                        { key: 'image', label: '', type: 'image', resource: 'category', uploadKey: 'id' },
                    ]
                ]
            }
        },
    }
});

export {
    category
}