// Landing page, will display information at a glance relative to the users auth
// Will route to metrics pages to generate reports

// TO DO:
// ---------------------------------------
// - Button "Generate Sales Report"
//      - Rereoutes to sales page
// - Button "Generate User Report"
//      - Rereoutes to user page

<template>
    <UIBaseLayout>
    <form role="form" @submit.prevent="handleRequest()">
        <!-- Title -->
        <div class="card mb-3">
            <div class="pb-0 card-header p-3">
                <div class="d-lg-flex align-items-center">
                    <div>
                        <h1 class="h4 letter-wider mb-0"> Metrics </h1>
                    </div>
                 </div>
            </div>
            <div class="card-body pt-0 pb-3"></div>
        </div>
        <!-- Sales overview card -->
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="h5 mb-0"> Sales 
                    <argon-button class="float-end ms-3" size="sm">
                    <i class="fa fa-pencil me-1"/>
                        Generate Report
                    </argon-button>
                </h5>
            </div>
        </div>
        <!-- User overview card -->
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="h5 mb-0"> Users
                    <argon-button class="float-end ms-3" size="sm">
                    <i class="fa fa-pencil me-1"/>
                        Generate Report
                    </argon-button>
                </h5>
            </div>
        </div>
    </form>
    </UIBaseLayout>
</template>

<script>

import UIBaseLayout from "/@/components/layout/UIBaseLayout.vue";

export default {
    name: "MetricsOverview",
    components: {UIBaseLayout}
}
</script>