import {createCrudRoutes} from "/@/helpers/createCrudRoutes";

const adProvider = (components) => createCrudRoutes("adProvider", "/marketing/ad-providers", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            headers: [
                {key: 'name', title: 'Name', format: 'bold'},
                {key: 'createdAt', title: 'Created At', format: 'date'},
                {key: 'status', title: 'Status', format: 'status'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
        },
        add: {
            model: {
                type: 'ad-providers',
                name: '',
                config: '{}',
                status: 'inactive',
                description: '',
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'description', label: 'Description', type: 'text'},
                        {key: 'config', label: 'Config', type: 'codemirror'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'simple'},
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'description', label: 'Description', type: 'text'},
                        {key: 'config', label: 'Config', type: 'codemirror'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'simple'},
                    ]
                ]
            }
        }
    }
});

export {
    adProvider
}