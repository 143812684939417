import {createCrudRoutes} from "/@/helpers/createCrudRoutes";

const page = (components) => createCrudRoutes("page", "/content/pages", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            headers: [
                {key: 'title', title: 'Title', format: 'titleSlug'},
                {key: 'tags', title: 'Tags', format: 'tags'},
                {key: 'createdAt', title: 'Date Created', format: 'date'},
                {key: 'status', title: 'Status', format: 'status'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
        },
        add: {
            model: {
                type: 'pages',
                title: '',
                slug: '',
                content: '',
                status: 'draft',
                relationshipNames: ['author', 'tags'],
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'title', label: 'Title', type: 'text'},
                        {key: 'slug', label: 'Slug', type: 'text'},
                        {key: 'content', label: 'Content', type: 'html'},
                        {key: 'tags', label: 'Tags', type: 'select-dynamic', source: 'tag', sourceType: 'tags'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'default'},
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'title', label: 'Title', type: 'text'},
                        {key: 'slug', label: 'Slug', type: 'text'},
                        {key: 'content', label: 'Content', type: 'html'},
                        {key: 'tags', label: 'Tags', type: 'select-dynamic', source: 'tag', sourceType: 'tags'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'default'},
                    ]
                ]
            }
        }
    },
});

export {
    page
}