<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-6 col-12 d-flex ms-auto">
                <a href="javascript:;" class="btn btn-outline-secondary ms-auto">
                    Export
                </a>
                <div class="dropleft ms-3">
                    <button id="dropdownImport"
                            class="btn bg-gradient-dark dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                    >
                        Today
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownImport">
                        <li>
                            <a class="dropdown-item" href="javascript:;">Yesterday</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="javascript:;">Last 7 days</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="javascript:;">Last 30 days</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mb-3 col-xl-3 col-sm-6">
                <mini-statistics-card title="Users"
                                      value="930"
                                      :percentage="{ value: '+55%', color: 'success' }"
                                      :icon="{
                                        component: 'ni ni-circle-08',
                                        background: 'bg-gradient-dark',
                                      }"/>
            </div>
            <div class="mb-3 col-xl-3 col-sm-6">
                <mini-statistics-card title="New Users"
                                      value="744"
                                      :percentage="{ value: '+3%', color: 'success' }"
                                      :icon="{
            component: 'ni ni-world',
            background: 'bg-gradient-dark',
          }"
                />
            </div>
            <div class="mb-3 col-xl-3 col-sm-6">
                <mini-statistics-card title="Sessions"
                                      value="1,414"
                                      :percentage="{ value: '-2%', color: 'danger' }"
                                      :icon="{
            component: 'ni ni-watch-time',
            background: 'bg-gradient-dark',
          }"
                />
            </div>
            <div class="col-xl-3 col-sm-6">
                <mini-statistics-card title="Pages/Session"
                                      value="1.76"
                                      :percentage="{ value: '+5%', color: 'success' }"
                                      :icon="{
            component: 'ni ni-image',
            background: 'bg-gradient-dark',
          }"
                />
            </div>
        </div>
        <div class="row">
            <div class="mb-3 col-lg-7 col-md-12">
                <div class="card">
                    <div class="p-3 pb-0 card-header">
                        <h6 class="mb-0">Traffic channels</h6>
                        <div class="d-flex align-items-center">
              <span class="badge badge-md badge-dot me-4">
                <i class="bg-primary"></i>
                <span class="text-xs text-dark">Organic search</span>
              </span>
                            <span class="badge badge-md badge-dot me-4">
                <i class="bg-dark"></i>
                <span class="text-xs text-dark">Referral</span>
              </span>
                            <span class="badge badge-md badge-dot me-4">
                <i class="bg-info"></i>
                <span class="text-xs text-dark">Social media</span>
              </span>
                        </div>
                    </div>
                    <div class="p-3 card-body">
                        <div class="chart">
                            <traffic-chart/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3 col-lg-5 col-md-12">
                <default-doughnut-chart title="Refferals"
                                        :chart="{
            labels: ['Adobe', 'Atlassian', 'Slack', 'Spotify', 'Jira'],
            datasets: [{ label: 'Referrals', data: [25, 3, 12, 7, 10] }],
          }"
                                        :actions="{
            route: 'https://platform.heard.plus',
            label: 'See all referrals',
            color: 'secondary',
          }"
                />
            </div>
        </div>
        <div class="row">
            <div class="mb-3 col-sm-6">
                <social-card :items="[
            {
              label: 'Facebook',
              icon: 'facebook',
              progress: 80,
            },
            {
              label: 'Twitter',
              icon: 'twitter',
              progress: 40,
            },
            {
              label: 'Reddit',
              icon: 'reddit',
              progress: 30,
            },
            {
              label: 'Youtube',
              icon: 'youtube',
              progress: 25,
            },
            {
              label: 'Slack',
              icon: 'slack',
              progress: 15,
            },
          ]"
                />
            </div>
            <div class="col-sm-6">
                <analytics-card title="Pages"
                                :headers="['Page', 'Page Views', 'Avg. Time', 'Bounce Rate']"
                                :pages="[
            {
              url: '/bits',
              views: 345,
              time: '00:17:07',
              rate: '40.91%',
            },
          ]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MiniStatisticsCard from "/@/elements/cards/MiniStatisticsCard.vue";
import TrafficChart from "./components/TrafficChart.vue";
import DefaultDoughnutChart from "./components/DefaultDoughnutChart.vue";
import SocialCard from "./components/SocialCard.vue";
import AnalyticsCard from "./components/AnalyticsCard.vue";
import setTooltip from "/@/helpers/tooltip.js";

export default {
    name: "Analytics",
    components: {
        MiniStatisticsCard,
        TrafficChart,
        DefaultDoughnutChart,
        SocialCard,
        AnalyticsCard,
    },
    mounted() {
        setTooltip(this.$store.state.bootstrap);
    },
};
</script>
