<template>
    <UIBaseLayout>
        <form v-if="!showResults" role="form" @submit.prevent="handleRequest()">
            <!-- Title Card -->
            <div class="card mb-3">
                <div class="pb-0 card-header p-3">
                    <div class="d-lg-flex align-items-center">
                        <div>
                            <h1 class="h4 letter-wider mb-0">
                                Sales Reports
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0 pb-3"></div>
            </div>
            <!-- Input fields Card -->
            <div class="card mb-4">
                <div class="card-body">
                    <label>Report Options</label> <hr>
                    <div class="flex-container">

                        <!-- App ID -->
                        <div v-if="admin" class="flex-choice mb-3">
                            <label class="form-label"> App ID </label>
                            <select id="choices-app-id" v-model="appId" class="form-control" name="choices-app" multiple>
                                <option v-for="app in appList" :key="app.id" :value="app.id" :label="app.name"> {{ app.name }} </option>
                            </select>
                        </div>
                        
                        <!-- Filter selection -->
                        <div class="flex-item mb-3">
                            <label class="form-label">Model</label>
                            <select v-model="selectedFilter" class="form-select">
                                <option value="get_sales">Sales</option>
                                <option value="title_count">Title Count</option>
                                <option value="title_sales">Title Sales</option>
                                <option value="title_best_sellers">Best Sellers</option>
                            </select>
                        </div>
                        
                        <!-- Time and Date Input -->
                        <div>
                            <TimeDateSelector :time="time"
                                :years="years"
                                @update-time="updateTime"
                                @update-start-date="updateStartDate"
                                @update-end-date="updateEndDate"
                            />
                        </div>

                        <!-- Extra input Cards  -->
                        <div class="flex-container">
                            <div v-if="selectedFilter !== ''">
                                <FilterResolver :selection="selectedFilter" @update-data="handleUpdateData" />
                             </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Generate report button -->
            <div class="text-center mb-3">
                <argon-button size="sm" @click="handleRequest">
                    <i class="fa fa-pencil me-1"/>
                        Generate Report
                </argon-button>
            </div>

        </form>

        <!-- Results Card -->
        <div v-if="showResults">
            <!-- Graph -->
            <div v-if="renderGraph" class="card mb-4">
                <div class="card-body">
                    <GraphController 
                        :data="salesData" 
                        :graph-options="graphOptions" 
                        :time="time" :endpoint="endpoint">
                    </GraphController>
                </div>
            </div>
            <!-- Table -->
            <div class="card mb-4">
                <div class="card-body">
                    <argon-button size="sm" @click="showResults = false">Back</argon-button>
                    <argon-button size="sm" class="float-end ms-3" @click="exportResults = false">Export</argon-button>
                    <hr>
                    <DataTable :headers="tableHeaders" :table-ref="'salesTable'">
                        <tr v-for="(entry, index) in salesData" :key="index" class="data-row">
                            <td v-for="(value, key) in entry" :key="key" :style="{ paddingLeft: '24px' }">{{ value }}</td>
                        </tr>
                    </DataTable>
                </div>
            </div>
        </div>
    </UIBaseLayout>
</template>

<script>
import UIBaseLayout from "/@/components/layout/UIBaseLayout.vue";
import FilterResolver from "../Components/SalesFilterResolver.vue";
import DataTable from "/@/components/layout/UIDataTableLayout.vue";
import { mapGetters, mapActions } from 'vuex';
import TimeDateSelector from "../../Components/TimeDateSelector.vue";
import Choices from "choices.js";
import GraphController from "../../../Graphing/GraphController.vue";

export default {
    name: "SalesReportPage",
    components: { UIBaseLayout, FilterResolver, DataTable, TimeDateSelector, GraphController },
    data() {
        return {
            appId: [],
            time: '',
            startDate: '',
            endDate: '',
            years: this.generateYears(),
            title_id: '',
            purchase: true,
            subscription: false,
            selectedFilter: '',
            showResults: false,
            group: '',
            admin: true,
            debug: false,
            graphOptions: {},
            renderGraph: false,
            endpoint: "Sales",
        };
    },
    computed: {
        ...mapGetters('metrics', {
            sales: 'getMetric'
        }),
        ...mapGetters(['getAppId']),
        salesData() {
            return this.sales('sales', this.selectedFilter);
        },
        appList() {
            return this.$store.getters["app/list"]?.data;
        },
        tableHeaders() {
            if (this.salesData.length > 0) {
                return Object.keys(this.salesData[0]).map(key => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key
                }));
            }
            return [];
        },
    },
    created() {
        this.appId = [this.getAppId];
    },
    async mounted() {
        await this.$store.dispatch("app/getList");
        this.getChoices("choices-app-id", true, this.appId)
    },
    methods: {
        ...mapActions('metrics', {
            fetchMetric: 'fetchMetric'
        }),
        generateYears() {
            const currentYear = new Date().getFullYear();
            const startYear = 2024;
            let years = [];
            for (let year = startYear; year <= currentYear; year++) {
                years.push(year);
            }
            return years;
        },
        updateTime(newTime) {
            this.time = newTime;
        },
        updateStartDate(newStartDate) {
            this.startDate = newStartDate;
        },
        updateEndDate(newEndDate) {
            this.endDate = newEndDate;
        },
        getChoices(id, removeButton, selectedChoice) {
            if (document.getElementById(id)) {
                const element = document.getElementById(id);
                const selected = new Choices(element, {
                    searchEnabled: false,
                    removeItemButton: removeButton
                });
                selected.setChoiceByValue(selectedChoice);
            }
        },
        handleUpdateData(data) {
            if (data.titleId !== undefined) {
                this.title_id = data.titleId;
            }
            if (data.purchase !== undefined) {
                this.purchase = data.purchase;
            }
            if (data.subscription !== undefined) {
                this.subscription = data.subscription;
            }
            if (data.genBarGraph !== undefined) {
                this.genBarGraph = data.genBarGraph;
            }
            if (data.genLineGraph !== undefined) {
                this.genLineGraph = data.genLineGraph;
            }
            if (data.genPieChart !== undefined) {
                this.genPieChart = data.genPieChart;
            }
            if (data.group !== undefined) {
                this.group = data.group;
            }
        },
        async handleRequest() {
            let transaction_type = [];
            if (this.purchase && !this.subscription) {
                transaction_type = ["purchase"];
            } else if (this.purchase && this.subscription) {
                transaction_type = ["purchase", "subscription"];
            } else if (!this.purchase && this.subscription) {
                transaction_type = ["subscription"];
            } else {
                throw new Error("Must select transaction type");
            }

            this.graphOptions = {
                genBarGraph: this.genBarGraph,
                genLineGraph: this.genLineGraph,
                genPieChart: this.genPieChart
            }
            
            this.resolveRenderChart(this.graphOptions)

            const options = {
                app_id: this.appId,
                resolution: this.time,
                start_date: this.startDate,
                end_date: this.endDate,
                title_id: this.title_id,
                transaction_type: transaction_type,
                group: this.group,
            };

            //Debug
            if (this.debug) {console.log(this.genBarGraph, this.genLineGraph)};

            await this.fetchMetric({ options, metric: 'sales', filter: this.selectedFilter });
            this.showResults = true;
        },
        resolveRenderChart(graphOptions) {
            this.renderGraph = Object.values(graphOptions).some(value => value);
        }
    }
};
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem; 
}

.flex-item {
    flex: 1; 
    min-width: 100px; 
    max-width: 140px;
}

.flex-choice {
    flex: 1; 
    min-width: 200px;
    max-width: 210px;
}

.flex-box {
    flex: 1; 
    min-width: 200px;
}

</style>