<template>
    <!-- Graph Options Card-->
    <div class="vertical-line"></div>
    <label>Graph Options: </label>
    <div class="checkbox-group">
        <label class="form-label">Type</label>
        <div class="checkbox-item">
            <input type="checkbox" id="bar" v-model="genBarGraph" @change="emitData"/>
                <label for="bar">Bar</label>
        </div>
        <div class="checkbox-item">
            <input type="checkbox" id="line" v-model="genLineGraph" @change="emitData"/>
            <label for="line">Line</label>
        </div>
        <div class="checkbox-item">
            <input type="checkbox" id="pie" v-model="genPieChart" @change="emitData"/>
            <label for="pie">Pie</label>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['update-data'],
    data() {
        return {
            genBarGraph: false,
            genLineGraph: false,
            genPieChart: false,
        };
    },
    methods: {
        emitData() {
            this.$emit('update-data', { 
                genBarGraph: this.genBarGraph,
                genLineGraph: this.genLineGraph,
                genPieChart: this.genPieChart,
            });
        }
    }
}
</script>

<style>
.vertical-line {
    border-left: 2px solid rgb(220, 220, 220);
    height: 80px;
}
</style>

