<template>
    <div id="sidenav-collapse-main"
         class="collapse navbar-collapse w-auto h-auto h-100">
        <ul class="navbar-nav">
            <sidenav-item :to="{ name: 'dashboard' }"
                          mini-icon="fa-home"
                          text="Home"/>
            <template v-for="group in filteredNavigationGroups"
                      :key="group.title">
                <li class="nav-separator">
                    <span class="text-xs ms-2 text-uppercase font-weight-bolder">
                        {{ group.title }}
                    </span>
                </li>
                <sidenav-item v-for="item in group.items"
                              :key="item.routeName"
                              :to="{ name: item.routeName }"
                              :mini-icon="item.miniIcon"
                              :text="item.text"/>
            </template>
        </ul>
    </div>
</template>

<script>
import { computed, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import SidenavItem from './SidenavItem.vue';

export default defineComponent({
    name: 'SidenavList',
    components: {
        SidenavItem,
    },
    props: {
        navigationGroups: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        
        const userRole = computed(() => store.getters['profile/me']?.roles[0]?.name || '');

        const filteredNavigationGroups = computed(() =>
            props.navigationGroups.filter(group => group.roles.includes(userRole.value)).map(group => ({
                ...group,
                items: group.items.filter(item => item.roles ? item.roles.includes(userRole.value) : true),
            }))
        );

        // Force re-evaluation of filteredNavigationGroups when userRole changes
        watch(userRole, () => {
            console.log('User role changed:', userRole.value);
        });

        return {
            filteredNavigationGroups,
            userRole,
        };
    },
});
</script>
