<script setup lang="ts">
import {defineProps} from 'vue';

// Define props including the getDataFromPage function
const props = defineProps({
    pagination: {
        type: Object,
        default: () => {
        },
    },
    metaPage: {
        type: Object,
        default: () => {
        },
    }
});
</script>

<template>
    <div class="d-flex ps-2">
        <p class="mb-0 align-self-center text-muted">
            Showing <strong>{{ props.pagination.total ? props.metaPage?.from : 0 }}</strong> to
            <strong>{{ props.metaPage?.to }}</strong> of
            <strong>{{ props.pagination.total ?? 0 }}</strong> entries
        </p>
    </div>
</template>
