<template>
    <!-- Title ID -->
    <div class="flex-container">
        <div class="flex-item">
                <label class="form-label">Title ID</label>
                <input type="number" class="form-control" v-model="titleId" @input="emitData" />
            </div>
            <GraphOptions class="flex-item" @update-data="handleUpdateData"></GraphOptions>
        </div>
    
</template>

<script>
import GraphOptions from '../../Components/GraphOptions.vue';

export default {
    components: {
        GraphOptions,
    },
    emits: ['update-data'],
    data() {
        return {
            titleId: '',
            genBarGraph: false,
            genLineGraph: false,
        };
    },
    methods: {
        handleUpdateData(data) {
            if (data.genBarGraph !== undefined) {
                this.genBarGraph = data.genBarGraph;
            }
            if (data.genLineGraph !== undefined) {
                this.genLineGraph = data.genLineGraph;
            }
            this.emitData();
        },
        emitData() {
            this.$emit('update-data', { 
                titleId: this.titleId, 
                genBarGraph: this.genBarGraph,
                genLineGraph: this.genLineGraph
            });
        }
    }
};
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.flex-item {
    flex: 1; 
    min-width: 100px; 
    max-width: 120px;
}
</style>