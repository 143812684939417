import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const cast = (components) => createCrudRoutes("cast", "/content/cast", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            headers: [
                { key: 'name', title: 'Name', format: 'profile' },
                { key: 'titles_published', title: 'Linked titles' },
                { key: 'createdAt', title: 'Created At', format: 'date' },
                { key: 'action', title: 'Action', format: 'actions', sortable: false }
            ],
        },
        add: {
            model: {
                type: 'casts',
                name: '',
                surname: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text', class: 'col-md-6' },
                        { key: 'surname', label: 'Surname', type: 'text', class: 'col-md-6' },
                    ]
                ]
            }
        },
        edit: {
            layout: 'default',
            formFields: {
                main: [
                    [
                        { key: 'name', label: 'Name', type: 'text', class: 'col-md-6' },
                        { key: 'surname', label: 'Surname', type: 'text', class: 'col-md-6' }
                    ]
                ]
            }
        }
    }
});

export {
    cast
}