import { createCrudRoutes } from "/@/helpers/createCrudRoutes";

const subscriptionPlan = (components) => createCrudRoutes("subscriptionPlan", "/sales/subscription-plans", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            headers: [
                {key: 'name', title: 'Name', format: 'bold'},
                {key: 'planType', title: 'Type', format: 'inlineCode'},
                {key: 'period', title: 'Period'},
                {key: 'recurring', title: 'Recurring'},
                {key: 'price', title: 'Price'},
                {key: 'currency', title: 'Currency'},
                {key: 'startDate', title: 'Start Date', format: 'date'},
                {key: 'endDate', title: 'End Date', format: 'date'},
                {key: 'status', title: 'Status', format: 'status'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
        },
        add: {
            model: {
                type: 'subscription-plans',
                name: '',
                planType: 'Global Plan',
                period: '1',
                recurring: false,
                subscriptionId: '',
                status: 'inactive',
                startDate: '',
                endDate: '',
                price: 0.00,
                currency: 'GBP'
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'subscriptionId', label: 'Subscription ID', type: 'text'},
                        {key: 'period', label: 'Period', type: 'number', min: 1, max: 12, class: 'col-md-6'},
                        {key: 'recurring', label: 'Is Recurring', type: 'checkbox', class: 'col-md-6'},
                        {key: 'currency', label: 'Currency', type: 'text', class: 'col-md-6'},
                        {key: 'price', label: 'Price', type: 'number', min: 0.00, max: 9999.99, step: '0.01', class: 'col-md-6'},
                        {key: 'startDate', label: 'Start Date', type: 'date', class: 'col-md-6 mb-0'},
                        {key: 'endDate', label: 'End Date', type: 'date', class: 'col-md-6 mb-0'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'simple', class: 'mb-0'},
                    ],
                    [
                        {
                            key: 'planType', label: '', type: 'radio-group', options: [
                                {
                                    label: 'Global Plan',
                                    value: 'Global Plan',
                                },
                                {
                                    label: 'Channel Plan',
                                    value: 'Channel Plan',
                                },
                                {
                                    label: 'Author Plan',
                                    value: 'Author Plan',
                                },
                            ]
                        },
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'subscriptionId', label: 'Subscription ID', type: 'text'},
                        {key: 'period', label: 'Period', type: 'number', min: 1, max: 12, class: 'col-md-6'},
                        {key: 'recurring', label: 'Is Recurring', type: 'checkbox', class: 'col-md-6'},
                        {key: 'currency', label: 'Currency', type: 'text', class: 'col-md-6'},
                        {key: 'price', label: 'Price', type: 'number', min: 0.00, max: 9999.99, step: '0.01', class: 'col-md-6'},
                        {key: 'startDate', label: 'Start Date', type: 'date', class: 'col-md-6 mb-0'},
                        {key: 'endDate', label: 'End Date', type: 'date', class: 'col-md-6 mb-0'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'simple'},
                    ],
                    [
                        {
                            key: 'planType', label: '', type: 'radio-group', options: [
                                {
                                    label: 'Global Plan',
                                    value: 'Global Plan',
                                },
                                {
                                    label: 'Channel Plan',
                                    value: 'Channel Plan',
                                },
                                {
                                    label: 'Author Plan',
                                    value: 'Author Plan',
                                },
                            ]
                        },
                    ]
                ]
            }
        },
    }
});

export {
    subscriptionPlan
}