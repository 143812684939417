// import auth from "/@/middleware/auth";
// import staff from "/@/middleware/staff";

/**
 * Create a list of routes for a CRUD module.
 * 
 * @param {string} name - The name of the module.
 * @param {string} path - The base path for the module.
 * @param {object} options - Additional options for the module.
 * @returns {array} An array of route objects.
 */
export function createCrudRoutes(
    name,
    path,
    options = {}
) {
    const baseName = name.replace(/_/g, "-");
    // const middleware = options.middleware ?? [auth, staff];
    const auth = options.auth ?? true;
    const roles = options.roles ?? ['staff'];
    const cruds = options.cruds ?? ["list", "add", "edit"];
    const listProps = options.listProps ?? {};

    return cruds.map(crudType => ({
        path: `${path}/${crudType}${crudType === 'edit' ? '/:id' : ''}`,
        name: `${baseName}.${crudType}`,
        component: options.components[crudType],
        meta: {
            auth,
            roles,
            // middleware,
            pageTitle: `${crudType} ${baseName}`,
            namespace: name,
        },
        props: () => ({
            namespace: name,
            ...(listProps[crudType] ?? {})
        })
    }));
}
