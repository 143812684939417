import {createCrudRoutes} from "/@/helpers/createCrudRoutes";
import admin from "/@/middleware/admin";
import auth from "/@/middleware/auth";

const user = (components) => createCrudRoutes("user", "/users", {
    middleware: [auth, admin],
    roles: ['admin'],
    components: components,
    listProps: {
        list: {
            headers: [
                {key: 'name', title: 'Name', format: 'profile'},
                {key: 'email', title: 'Email'},
                {key: 'roles', title: 'Role', format: 'tags'},
                {key: 'createdAt', title: 'Created At', format: 'date'},
                {key: 'action', title: 'Action', format: 'actionEdit', sortable: false}
            ],
        },
        add: {
            model: {
                type: "users",
                name: "",
                email: "",
                profile_image: null,
                password: "",
                password_confirmation: "",
                relationshipNames: ["roles"],
                roles: [],
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'email', label: 'Email', type: 'email'},
                        {key: 'roles', label: 'Roles', type: 'select-dynamic', source: 'role', sourceType: 'roles', multiple: true},
                        {key: 'password', label: 'Password', type: 'password'},
                        {key: 'password_confirmation', label: 'Confirm Password', type: 'password'},
                    ]
                ],
                side: [
                    [

                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'email', label: 'Email', type: 'email'},
                        {key: 'roles', label: 'Roles', type: 'select-dynamic', source: 'role', sourceType: 'roles', multiple: true},
                        {key: 'password', label: 'Password', type: 'password'},
                        {key: 'password_confirmation', label: 'Confirm Password', type: 'password'},
                    ]
                ],
                side: [
                    [
                        {key: 'profile_image', label: '', type: 'image', resource: 'channel', uploadKey: 'id' },
                    ]
                ]
            }
        }
    }
});

export {
    user
}
