<template>
    <UIListPage :namespace="namespace" :headers="headers" :show-add-button="true"/>
</template>

<script>
import UIListPage from "/@/components/pages/ListPage.vue";
export default {
    name: "ListCustomerPage",
    components: {
        UIListPage,
    },
    data() {
        return {
            namespace: 'user',
            headers: [
                {key: 'name', title: 'Name', format: 'profile'},
                {key: 'status', title: 'Status', format: 'status'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
            dataTable: null,
        }
    },
};
</script>