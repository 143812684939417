import {createCrudRoutes} from "/@/helpers/createCrudRoutes";

const channel = (components) => createCrudRoutes("channel", "/content/channels", {
    components: components,
    roles: ['admin'],
    listProps: {
        list: {
            headers: [
                {key: 'name', title: 'Name', format: 'bold'},
                {key: 'createdAt', title: 'Created At', format: 'date'},
                {key: 'status', title: 'Status', format: 'status'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
        },
        add: {
            model: {
                type: 'channels',
                name: '',
                status: 'draft',
                image: '',
                description: '',
            },
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'description', label: 'Description', type: 'html'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'default'},
                    ]
                ]
            }
        },
        edit: {
            layout: 'side',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                        {key: 'description', label: 'Description', type: 'html'},
                    ]
                ],
                side: [
                    [
                        {key: 'status', label: '', type: 'status', statusType: 'default'},
                    ],
                    [
                        {key: 'image', label: '', type: 'image', resource: 'channel', uploadKey: 'id' },
                    ]
                ]
            }
        },
    }
});

export {
    channel
}