import {createCrudRoutes} from "/@/helpers/createCrudRoutes";

const review = (components) => createCrudRoutes("review", "/sales/reviews", {
    components: components,
    roles: ['admin'],
    cruds: ["list"],
    listProps: {
        list: {
            showAddButton: false,
            sortDirection: '-createdAt',
            headers: [
                {key: 'id', title: 'ID'},
                {key: 'customerId', title: 'Customer'},
                {key: 'titleId', title: 'Title'},
                {key: 'rating', title: 'Rating'},
                {key: 'score', title: 'Score'},
                {ley: 'message', title: 'Message'},
                {key: 'createdAt', title: 'Date', format: 'date'},
            ],
        },
    }
});

export {
    review
}