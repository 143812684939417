/* eslint-disable no-console */
import ApiService from '/@/services/api.service';

/**
 * Generates a dynamic Vuex module.
 * @param {Object} options - Configuration options for the module.
 * @returns {Object} A Vuex module.
 */
export function generateDynamicModule(options) {
    const {
        // eslint-disable-next-line no-unused-vars
        moduleName,
        resource = '',
        include = '',
        useApp =  false,
        initialState = {},
        actions = {},
        mutations = {},
        getters = {},
    } = options;

    const apiService = new ApiService(resource, {
        include: include
    });
    // Define the module
    return {
        namespaced: true, // Using namespaced modules for better modularity
        state: () => ({
            ...initialState,
            items: null,
            item: null,
            image: null,
            asset: null,
            params: {
                sort: 'id',
                filter: {},
                page: {
                    number: 1,
                    size: 25,
                }
            }
        }),
        actions: {
            ...actions,
            async getList({commit, dispatch, rootState}, params) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    if (useApp) {
                        const appId = rootState.appId; // Assume `appId` is directly under rootState
                        apiService.setApp(appId ?? null);
                    }
                    const data = await apiService.getAll(params);
                    commit('LIST_RECORDS', data);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },
            async get({commit, dispatch, rootState}, itemId) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    if (useApp) {
                        const appId = rootState.appId; // Assume `appId` is directly under rootState
                        apiService.setApp(appId ?? null);
                    }
                    const data = await apiService.get(itemId);
                    commit('GET_RECORD', data);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },
            async add({commit, dispatch, rootState}, item) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    if (useApp) {
                        const appId = rootState.appId; // Assume `appId` is directly under rootState
                        apiService.setApp(appId ?? null);
                        item.app_id = parseInt(appId);
                    }

                    const data = await apiService.create(item);
                    commit('GET_RECORD', data);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },
            async edit({dispatch, rootState}, item) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    if (useApp) {
                        const appId = rootState.appId; // Assume `appId` is directly under rootState
                        apiService.setApp(appId ?? null);
                        item.app_id = parseInt(appId);
                    }
                    await apiService.edit(item);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },
            // eslint-disable-next-line no-empty-pattern
            async delete({dispatch, rootState}, itemId) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    if (useApp) {
                        const appId = rootState.appId; // Assume `appId` is directly under rootState
                        apiService.setApp(appId ?? null);
                    }
                    await apiService.remove(itemId);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },

            async uploadImage({commit, dispatch}, file) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    const data = await apiService.uploadAsset(file, 'image', 'attachment');
                    commit('UPLOAD_IMAGE', data);
                } catch (error) {
                    console.error("Failed to upload image", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },
            async uploadAsset({commit, dispatch}, item) {
                dispatch('loading/setLoading', true, {root: true}); // Start loading
                try {
                    const data = await apiService.uploadAsset(item, 'asset', 'asset');
                    commit('UPLOAD_ASSET', data);
                } catch (error) {
                    console.error("Failed to upload asset:", error);
                    throw error;
                } finally {
                    dispatch('loading/setLoading', false, {root: true}); // End loading
                }
            },
            async clearFilters({commit}) {
                commit('CLEAR_FILTERS');
            },
            async setSort({commit}, sort) {
                commit('SET_SORT', sort);
            },
            async setFilter({commit}, filter) {
                commit('SET_FILTER', filter);
            },
            async setPage({commit}, page) {
                commit('SET_PAGE', page);
            },
            async setPageSize({commit}, size) {
                commit('SET_PAGE_SIZE', size);
            }
        },
        mutations: {
            ...mutations,
            LIST_RECORDS(state, data) {
                state.items = data;
            },
            GET_RECORD(state, data) {
                state.item = data;
            },
            UPLOAD_IMAGE(state, data) {
                state.image = data;
            },
            UPLOAD_ASSET(state, data) {
                state.asset = data;
            },
            CLEAR_FILTERS(state) {
                state.params.filter = {};
            },
            SET_SORT(state, sort) {
                state.params.sort = sort;
            },
            SET_FILTER(state, params) {
                state.params.filter = {
                    ...(params.query ? {name: params.query} : {}),
                    ...(params.name ? {name: params.name} : {}),
                    ...(params.authorName ? {author_name: params.authorName} : {}),
                    ...(params.title ? {title: params.title} : {}),
                    ...(params.tag ? {tag: params.tag} : {}),
                    ...(params.category ? {category: params.category} : {}),
                    ...(params.status ? {status: params.status} : {}),
                    ...params.other ?? {}
                };/* eslint-disable no-console */
            },
            SET_PAGE(state, page) {
                state.params.page.number = page
            },
            SET_PAGE_SIZE(state, size) {
                state.params.page.size = size
            }
        },
        getters: {
            ...getters,
            list: state => state.items,
            item: state => state.item,
            image: state => state.image,
            asset: state => state.asset,
            params: state => state.params,
        },
    };
}
