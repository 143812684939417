/**
 * This project is based off Vue Argon Dashboard 2 PRO - v3.0.0 licensed under GNU GPL 2
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 *
 * Substantial code was modified for the needs of Heard Digital
 * Copyright 2023 Heard Digital
 */

import {createApp} from "vue";
import {createI18n} from 'vue-i18n'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";

import {messages} from './i18n'

import * as Sentry from "@sentry/vue"
import UILoadingBar from './elements/UILoadingBar.vue';
import ArgonInput from "./elements/fields/ArgonInput.vue";
import ValidationError from "./elements/fields/ValidationError.vue";
import ArgonButton from "./elements/fields/ArgonButton.vue";
import UIRadio from "./elements/fields/UIRadio.vue";
import ArgonCheckbox from "./elements/fields/ArgonCheckbox.vue";

import {basicSetup} from "codemirror";
import {json} from "@codemirror/lang-json";
import {oneDark} from "@codemirror/theme-one-dark";
import VueCodemirror from 'vue-codemirror';

const i18n = createI18n({
    locale: 'en',
    legacy: false,
    globalInjection: true,
    messages,
})

const appInstance = createApp(App);

Sentry.init({
    appInstance: appInstance,
    dsn: "https://f39737666c1d488bb5bd909f9784bd71@o4505557563736064.ingest.sentry.io/4505569557217280",
    integrations: [
        Sentry.browserTracingIntegration({router}),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api.heard\.plus\/api/, import.meta.env.VUE_APP_API_BASE_URL], // https://api.heard.plus/api/v1

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already smapling this entire session, change the sample rate to 100% when smapling sessions where errors occur
});

appInstance.use(VueCodemirror, {
    // optional default global options
    autofocus: true,
    disabled: false,
    indentWithTab: true,
    tabSize: 2,
    placeholder: 'Provide JSON configuration..',
    extensions: [basicSetup, json(), oneDark]
})

appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(i18n);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);

appInstance.component('UILoadingBar', UILoadingBar);
appInstance.component('ArgonInput', ArgonInput);
appInstance.component('ArgonButton', ArgonButton);
appInstance.component('UIRadio', UIRadio);
appInstance.component('ArgonCheckbox', ArgonCheckbox);
appInstance.component('ValidationError', ValidationError);

appInstance.mount("#app");