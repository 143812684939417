<template>
    <div class="form-check mt-2">
        <input :id="id"
               class="form-check-input"
               type="checkbox"
               :name="name"
               :checked="checked"
               @input="$emit('update:modelValue', $event.target.checked)"
        />
        <label :for="id" class="custom-control-label" :class="$attrs.class">
            <slot/>
        </label>
    </div>
</template>

<script>
export default {
    name: "ArgonCheckbox",
    props: {
        name: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        checked: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
};
</script>
