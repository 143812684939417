<template>
    <div>
      <component :is="component.type"
        v-for="(component, index) in graphComponents"
        :key="index"
        v-bind="component.props"
      />
    </div>
  </template>
  
<script>
import BarChart from "../Graphing/Components/BarChart.vue";
import GradientLineChart from "../Graphing/Components/GradientLineChart.vue";
import StackedBarChart from "../Graphing/Components/StackedBarChart.vue";
import PieChart from "../Graphing/Components/PieChart.vue";
  
export default {
    components: {
        BarChart,
        GradientLineChart,
        StackedBarChart,
        PieChart,
    },
    props: {
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
        graphOptions: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        time: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        }
    },
    computed: {

        processData() {
            const extractedData = {};
            const monthNames = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            this.data.forEach((item) => {
                Object.keys(item).forEach((key) => {
                    if (!extractedData[key]) {
                        extractedData[key] = [];
                    }
                    if (key === 'month') {
                        extractedData[key].push(monthNames[item[key] - 1]);
                    } else {
                        extractedData[key].push(item[key]);
                    }
                });
            });

            return extractedData;
        },


        resolveTime(){
            switch(this.time) {
            case "all_time":
                return this.processData.year;
            case "year_to_year":
                return this.processData.year;
            case "year":
                return this.processData.month;
            case "month_to_month":
                return this.processData.month;
            case "month":
                return this.processData.day;
            default:
                return null;
            }
        },

        graphComponents() {
            const components = [];
            const datasets = Object.keys(this.processData).map((key) => {
                return {
                    label: key,
                    data: this.processData[key],
                    backgroundColor: this.getColorForKey(key),
                };
            });

            const sortedDatasets = datasets.sort((a, b) => {
                if (a.label === 'amount') return 1;
                if (b.label === 'amount') return -1;
                return 0;
            });
    
            if (this.graphOptions.genBarGraph) {
                components.push({
                    type: BarChart,
                    props: {
                        chart: {
                            labels: this.resolveTime,
                            datasets: sortedDatasets.filter(dataset => dataset.label !== 'month' && dataset.label !== 'year' && dataset.label !== 'day'),
                        },
                    },
                });
            }

            if (this.graphOptions.genLineGraph) {
                components.push({
                    type: GradientLineChart,
                    props: {
                        chart: {
                            labels: this.resolveTime,
                            datasets: sortedDatasets.filter(dataset => dataset.label !== 'month' && dataset.label !== 'year' && dataset.label !== 'day'),
                        },
                    },
                });
            }

            if (this.graphOptions.genPieChart) {
                components.push({
                    type: PieChart,
                    props: {
                        chart: {
                            labels: this.resolveTime,
                            datasets: sortedDatasets.filter(dataset => dataset.label !== 'month' && dataset.label !== 'year' && dataset.label !== 'day'),
                        },
                    },
                });
            }

            return components;
        },
    },

    methods: {
        getColorForKey(key) {
            const colors = {
                purchases: "rgba(173,42,42,100)",
                subscriptions: "rgba(42,140,173,100)",
                amount:  "rgba(140,42,173,100)",
            };
            return colors[key] || "#000000";
        }
    }
};
</script>