export default {
    labels: {
        common: {
            add: 'Add',
            edit: 'Edit',
            list: 'List',
            cancel: 'Cancel',
            back: 'Back',
            refresh: 'Refresh',
            backToList: '@:labels.common.back to list',
        },
        buttons: {
            login: 'Login',
            logout: 'Log out',
            addNew: '@:labels.common.add New',
            update: 'Update',
            delete: 'Delete',
            edit: '@:labels.common.edit',
            cancel: '@:labels.common.cancel',
            back: '@:labels.common.back',
        },
        messages: {
            generalError: 'Something went wrong! Please try again',
            generalAddSuccess: 'Record added successfully!',
            generalUpdateSuccess: 'Record updated successfully!',
        },
        pages: {
            common: {
                base: 'Record',
                list: 'Records',
                add: '@:labels.common.add @:labels.pages.common.base',
                edit: '@:labels.common.edit @:labels.pages.common.base',
            },
            title: {
                base: 'Title',
                list: 'Titles',
                add: '@:labels.common.add @:labels.pages.title.base',
                edit: '@:labels.common.edit @:labels.pages.title.base',
            },
            author: {
                base: 'Author',
                list: 'Authors',
                add: '@:labels.common.add @:labels.pages.author.base',
                edit: '@:labels.common.edit @:labels.pages.author.base',
            },
            category: {
                base: 'Category',
                list: 'Categories',
                add: '@:labels.common.add @:labels.pages.category.base',
                edit: '@:labels.common.edit @:labels.pages.category.base',
            },
            tag: {
                base: 'Tag',
                list: 'Tags',
                add: '@:labels.common.add @:labels.pages.tag.base',
                edit: '@:labels.common.edit @:labels.pages.tag.base',
            },
            channel: {
                base: 'Channel',
                list: 'Channels',
                add: '@:labels.common.add @:labels.pages.channel.base',
                edit: '@:labels.common.edit @:labels.pages.channel.base',
            },
            rating: {
                base: 'Rating',
                list: 'Ratings',
                add: '@:labels.common.add @:labels.pages.rating.base',
                edit: '@:labels.common.edit @:labels.pages.rating.base',
            },
            campaign: {
                base: 'Campaign',
                list: 'Campaigns',
                add: '@:labels.common.add @:labels.pages.campaign.base',
                edit: '@:labels.common.edit @:labels.pages.campaign.base',
            },
            adProvider: {
                base: 'Ad Provider',
                list: 'Ad Providers',
                add: '@:labels.common.add @:labels.pages.adProvider.base',
                edit: '@:labels.common.edit @:labels.pages.adProvider.base',
            },
            appSetting: {
                base: 'Setting',
                list: 'Settings',
                add: '@:labels.common.add @:labels.pages.settings.base',
                edit: '@:labels.common.edit @:labels.pages.settings.base',
            },
            subscription: {
                base: 'Subscription',
                list: 'Subscriptions',
                add: '@:labels.common.add @:labels.pages.subscription.base',
                edit: '@:labels.common.edit @:labels.pages.subscription.base',
            },
            subscriptionPlan: {
                base: 'Subscription Plan',
                list: 'Subscription Plans',
                add: '@:labels.common.add @:labels.pages.subscription.base',
                edit: '@:labels.common.edit @:labels.pages.subscription.base',
            },
            user: {
                base: 'User',
                list: 'Users',
                add: '@:labels.common.add @:labels.pages.user.base',
                edit: '@:labels.common.edit @:labels.pages.user.base',
            },
            role: {
                base: 'Role',
                list: 'Roles',
                add: '@:labels.common.add @:labels.pages.role.base',
                edit: '@:label.common.edit @:labels.pages.role.base',
            },
            transaction: {
                base: 'Transaction',
                list: 'Transactions',
                add: '@:labels.common.add @:labels.pages.transaction.base',
                edit: '@:label.common.edit @:labels.pages.transaction.base',
            },
            region: {
                base: 'Region',
                list: 'Regions',
                add: '@:labels.common.add @:labels.pages.region.base',
                edit: '@:label.common.edit @:labels.pages.region.base',
            },
            userPurchase: {
                base: 'Purchase',
                list: 'Purchases',
                add: '@:labels.common.add @:labels.pages.userPurchase.base',
                edit: '@:label.common.edit @:labels.pages.userPurchase.base',
            },
            review: {
                base: 'Review',
                list: 'Reviews',
                add: '@:labels.common.add @:labels.pages.region.base',
                edit: '@:label.common.edit @:labels.pages.region.base',
            },
            page: {
                base: 'Static Page',
                list: 'Static Pages',
                add: '@:labels.common.add @:labels.pages.page.base',
                edit: '@:label.common.edit @:labels.pages.page.base',
            },
            slider: {
                base: 'Slider',
                list: 'Sliders and Banners',
                add: '@:labels.common.add @:labels.pages.slider.base',
                edit: '@:label.common.edit @:labels.pages.slider.base',
            },
            app: {
                base: 'App',
                list: 'Apps',
                add: '@:labels.common.add @:labels.pages.app.base',
                edit: '@:label.common.edit @:labels.pages.app.base',
            },
            series: {
                base: 'Series',
                list: 'Series',
                add: '@:labels.common.add @:labels.pages.series.base',
                edit: '@:label.common.edit @:labels.pages.series.base',
            },
            contentType: {
                base: 'Content Type',
                list: 'Content Types',
                add: '@:labels.common.add @:labels.pages.contentType.base',
                edit: '@:label.common.edit @:labels.pages.contentType.base',
            },
            metrics: {
                base: 'Metric',
                list: 'Metrics',
                add: '@:labels.common.add @:labels.pages.app.base',
                edit: '@:label.common.edit @:labels.pages.app.base',
            },
            reports: {
                base: 'Report',
                list: 'Reports',
                add: '@:labels.common.add @:labels.pages.app.base',
                edit: '@:label.common.edit @:labels.pages.app.base',
            },
            cast: {
                base: 'Cast',
                list: 'Casts',
                add: '@:labels.common.add @:labels.pages.cast.base',
                edit: '@:label.common.edit @:labels.pages.cast.base',
            }
        }
    }
}