<template>
    <main>
        <UICustomerHeader />
        <div class="py-4 container-fluid">
            <div class="mt-3 row">
                <div class="col-12 col-md-6 col-xl-4">
                    <div class="card h-100">
                        <div class="p-3 pb-0 card-header">
                            <h6 class="mb-0">Platform Settings</h6>
                        </div>
                        <div class="p-3 card-body">
                            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                                Account
                            </h6>
                            <ul class="list-group">
                                <li class="px-0 border-0 list-group-item">
                                    <UISwitch id="flexSwitchCheckDefault"
                                              name="email"
                                              label-class="mb-0 text-body ms-3 text-truncate w-80"
                                              checked
                                    >Email me when someone follows me
                                    </UISwitch>
                                </li>
                                <li class="px-0 border-0 list-group-item">
                                    <UISwitch id="flexSwitchCheckDefault1"
                                              name="Email"
                                              label-class="mb-0 text-body ms-3 text-truncate w-80"
                                    >Email me when someone answers on my post
                                    </UISwitch>
                                </li>

                                <li class="px-0 border-0 list-group-item">
                                    <UISwitch id="flexSwitchCheckDefault2"
                                              name="Email"
                                              label-class="mb-0 text-body ms-3 text-truncate w-80"
                                              checked
                                    >Email me when someone mentions me
                                    </UISwitch>
                                </li>
                            </ul>
                            <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
                                Application
                            </h6>
                            <ul class="list-group">
                                <li class="px-0 border-0 list-group-item">
                                    <UISwitch id="flexSwitchCheckDefault3"
                                              name="Project Launch"
                                              label-class="mb-0 text-body ms-3 text-truncate w-80"
                                    >New launches and projects
                                    </UISwitch>
                                </li>
                                <li class="px-0 border-0 list-group-item">
                                    <UISwitch id="flexSwitchCheckDefault4"
                                              name="Product Update"
                                              label-class="mb-0 text-body ms-3 text-truncate w-80"
                                              checked
                                    >Monthly product updates
                                    </UISwitch>
                                </li>
                                <li class="px-0 pb-0 border-0 list-group-item">
                                    <UISwitch id="flexSwitchCheckDefault5"
                                              name="Newsletter"
                                              label-class="mb-0 text-body ms-3 text-truncate w-80"
                                    >Subscribe to newsletter
                                    </UISwitch>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                    <profile-info-card title="Profile Information"
                                       description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                                       :info="{
              fullName: 'Alec M. Thompson',
              mobile: '(44) 123 1234 123',
              email: 'alecthompson@mail.com',
              location: 'USA',
            }"
                                       :social="[
              {
                link: 'https://www.facebook.com/CreativeTim/',
                icon: 'fab fa-facebook fa-lg',
              },
              {
                link: 'https://twitter.com/creativetim',
                icon: 'fab fa-twitter fa-lg',
              },
              {
                link: 'https://www.instagram.com/creativetimofficial/',
                icon: 'fab fa-instagram fa-lg',
              },
            ]"
                                       :action="{
              route: 'javascript:;',
              tooltip: 'Edit Profile',
            }"
                    />
                </div>
                <div class="mt-4 col-12 col-xl-4 mt-xl-0">
                    <div class="card h-100">
                        <div class="p-3 pb-0 card-header">
                            <h6 class="mb-0">Conversations</h6>
                        </div>
                        <div class="p-3 card-body">
                            <ul class="list-group">
                                <li
                                        class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                                >
                                    <UIAvatar class="me-3"
                                              :img="sophie"
                                              alt="kal"
                                              border-radius="lg"
                                              shadow="regular"
                                    />
                                    <div
                                            class="d-flex align-items-start flex-column justify-content-center"
                                    >
                                        <h6 class="mb-0 text-sm">Sophie B.</h6>
                                        <p class="mb-0 text-xs">Hi! I need more information..</p>
                                    </div>
                                    <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                                       href="javascript:;"
                                    >Reply</a
                                    >
                                </li>
                                <li
                                        class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                                >
                                    <UIAvatar class="me-3"
                                              :img="marie"
                                              alt="kal"
                                              border-radius="lg"
                                              shadow="regular"
                                    />
                                    <div
                                            class="d-flex align-items-start flex-column justify-content-center"
                                    >
                                        <h6 class="mb-0 text-sm">Anne Marie</h6>
                                        <p class="mb-0 text-xs">Awesome work, can you..</p>
                                    </div>
                                    <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                                       href="javascript:;"
                                    >Reply</a
                                    >
                                </li>
                                <li
                                        class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                                >
                                    <UIAvatar class="me-3"
                                              :img="ivana"
                                              alt="kal"
                                              border-radius="lg"
                                              shadow="regular"
                                    />
                                    <div
                                            class="d-flex align-items-start flex-column justify-content-center"
                                    >
                                        <h6 class="mb-0 text-sm">Ivanna</h6>
                                        <p class="mb-0 text-xs">About files I can..</p>
                                    </div>
                                    <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                                       href="javascript:;"
                                    >Reply</a
                                    >
                                </li>
                                <li
                                        class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                                >
                                    <UIAvatar class="me-3"
                                              :img="peterson"
                                              alt="kal"
                                              border-radius="lg"
                                              shadow="regular"
                                    />
                                    <div
                                            class="d-flex align-items-start flex-column justify-content-center"
                                    >
                                        <h6 class="mb-0 text-sm">Peterson</h6>
                                        <p class="mb-0 text-xs">Have a great afternoon..</p>
                                    </div>
                                    <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                                       href="javascript:;"
                                    >Reply</a
                                    >
                                </li>
                                <li
                                        class="px-0 border-0 list-group-item d-flex align-items-center"
                                >
                                    <UIAvatar class="me-3"
                                              :img="nick"
                                              alt="kal"
                                              border-radius="lg"
                                              shadow="regular"
                                    />
                                    <div
                                            class="d-flex align-items-start flex-column justify-content-center"
                                    >
                                        <h6 class="mb-0 text-sm">Nick Daniel</h6>
                                        <p class="mb-0 text-xs">Hi! I need more information..</p>
                                    </div>
                                    <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto"
                                       href="javascript:;"
                                    >Reply</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 row">
                <div class="col-12">
                    <div class="mb-4 card">
                        <div class="p-3 pb-0 card-header">
                            <h6 class="mb-1">Projects</h6>
                            <p class="text-sm">Architects design houses</p>
                        </div>
                        <div class="p-3 card-body">
                            <div class="row">
                                <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                                    <default-project-card title="Modern"
                                                          :img="img1"
                                                          label="Project #2"
                                                          description="As Uber works through a huge amount of internal
                management turmoil."
                                                          :authors="[
                      {
                        image: placeholder,
                        name: 'Elena Morison',
                      },
                      {
                        image: placeholder,
                        name: 'Ryan Milly',
                      },
                      {
                        image: placeholder,
                        name: 'Nick Daniel',
                      },
                      {
                        image: placeholder,
                        name: 'Peterson',
                      },
                    ]"
                                                          :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                                    />
                                </div>
                                <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                                    <default-project-card title="Scandinavian"
                                                          :img="img2"
                                                          label="Project #1"
                                                          description="Music is something that every person has his or her own
                      specific opinion about."
                                                          :authors="[
                      {
                        image: placeholder,
                        name: 'Nick Daniel',
                      },
                      {
                        image: placeholder,
                        name: 'Peterson',
                      },
                      {
                        image: placeholder,
                        name: 'Elena Morison',
                      },
                      {
                        image: placeholder,
                        name: 'Ryan Milly',
                      },
                    ]"
                                                          :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                                    />
                                </div>
                                <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                                    <default-project-card title="Minimalist"
                                                          :img="img3"
                                                          label="Project #3"
                                                          description="Different people have different taste, and various types
                      of music."
                                                          :authors="[
                      {
                        image: placeholder,
                        name: 'Peterson',
                      },
                      {
                        image: placeholder,
                        name: 'Nick Daniel',
                      },
                      {
                        image: placeholder,
                        name: 'Elena Morison',
                      },
                      {
                        image: placeholder,
                        name: 'Ryan Milly',
                      },
                    ]"
                                                          :action="{
                      color: 'success',
                      label: 'View Project',
                    }"
                                    />
                                </div>
                                <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                                    <placeholder-card has-border
                                                      :title="{ text: 'New project', variant: 'h5' }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import UISwitch from "/@/elements/fields/UISwitch.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import UIAvatar from "/@/elements/UIAvatar.vue";
import placeholder from "/@/assets/img/placeholder.jpg";
import image from "/@/assets/img/curved-images/curved1.jpg";

import DefaultProjectCard from "./components/DefaultProjectCard.vue";
import PlaceholderCard from "/@/elements/cards/PlaceholderCard.vue";
import setNavPills from "/@/assets/js/nav-pills.js";
import setTooltip from "/@/helpers/tooltip.js";
import UICustomerHeader from './UICustomerHeader.vue'

const body = document.getElementsByTagName("body")[0];

export default {
    name: "ProfileOverview",
    components: {
    UISwitch,
    ProfileInfoCard,
    UIAvatar,
    DefaultProjectCard,
    PlaceholderCard,
    UICustomerHeader
},
    data() {
        return {
            showMenu: false,
            placeholder,
            image,
        };
    },

    mounted() {
        this.$store.state.isAbsolute = true;
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
    },
    beforeMount() {
        this.$store.state.layout = "custom";
        this.$store.state.showNavbar = false;
        this.$store.state.showFooter = true;
        this.$store.state.hideConfigButton = true;
        body.classList.add("profile-overview");
    },
    beforeUnmount() {
        this.$store.state.isAbsolute = false;
        this.$store.state.layout = "default";
        this.$store.state.showNavbar = true;
        this.$store.state.showFooter = true;
        this.$store.state.hideConfigButton = false;
        body.classList.remove("profile-overview");
    },
};
</script>
