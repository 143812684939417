<template>
    <nav id="navbarBlur"
         class="navbar navbar-main navbar-expand-lg mb-3 px-0 bg-white border-radius-md shadow-none"
         :class="`${!isNavFixed ? '' : `position-sticky bg-white left-auto top-2 z-index-sticky`}`" v-bind="$attrs"
         data-scroll="true">
        <div class="px-3 py-1 container-fluid">
            <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
                <a href="#" class="p-0 nav-link text-body" @click.prevent="navbarMinimize">
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line bg-gray-500"></i>
                        <i class="sidenav-toggler-line bg-gray-500"></i>
                        <i class="sidenav-toggler-line bg-gray-500"></i>
                    </div>
                </a>
            </div>

            <breadcrumbs :current-page="currentRouteName"
                         :current-directory="currentDirectory"/>

            <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4">
                <div class="pe-md-3 d-flex align-items-center ms-md-auto"></div>
                <template v-if="appList && appList.length">
                    <div class="dropdown me-3 z-index-1">
                        <a id="appSelectDropdown"
                           href="#"
                           class="btn bg-gray-100 shadow-none dropdown-toggle mb-0"
                           data-bs-toggle="dropdown">
                            <span class="me-2">
                                {{ selectedApp }}
                            </span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end shadow position-absolute mt-0"
                            aria-labelledby="appSelectDropdown">
                            <li v-for="app in appList" :key="app.id">
                                <a class="dropdown-item py-2"
                                   :class="{'text-bold bg-light': app.id === appId}"
                                   @click="chooseApp(app.id)">
                                    {{ app.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </template>

                <ul class="navbar-nav justify-content-end">
                    <li v-if="loggedIn" class="nav-item d-flex align-items-center">
                        <a id="dropdownProfileMenu"
                           href="#"
                           style="display: contents;"
                           :class="`nav-link ${showProfileDropdown ? 'show' : ''}`"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                           @click="showProfileDropdown = !showProfileDropdown">
                            <span class="avatar avatar-sm rounded-circle">
                                <img :src="profileImage()"
                                     class="h-100 profile-image"
                                     alt="">
                            </span>
                            <span class="d-sm-inline d-none text-bold p-2">
                                {{ getNameNavbar }}
                            </span>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end shadow position-absolute mt-0"
                            :class="showProfileDropdown ? 'show' : ''"
                            aria-labelledby="dropdownProfileMenu">
                            <li class="border-radius-md" style="padding: 0.3rem 1rem;">
                                <small>
                                    Welcome
                                </small>
                            </li>
                            <router-link :to="{ name: 'user.profile' }">
                                <li>
                                    <a class="dropdown-item border-radius-md"
                                       href="javascript:">
                                        <div>
                                            <i class="fas fa-user-astronaut"></i>
                                            <span class="ms-2 text-bold">
                                                My Profile
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </router-link>
                            <li>
                                <hr class="m-2">
                            </li>
                            <li>
                                <a class="dropdown-item border-radius-md"
                                   href="#"
                                   @click.prevent="handleLogout">
                                    <div>
                                        <i class="fa fa-sign-out"></i>
                                        <span class="ms-2 text-bold">
                                            Logout
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li v-else class="nav-item d-flex align-items-center">
                        <router-link :to="{ name: 'login' }"
                                     class="px-0 nav-link font-weight-bold"
                                     :class="isNavFixed ? ' opacity-8 text-dark' : 'text-white'"
                                     target="_blank">
                            <i class="fa fa-user me-sm-1"></i>
                            <span class="d-sm-inline d-none">Sign In</span>
                        </router-link>
                    </li>
                    <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a id="iconNavbarSidenav"
                           href="#"
                           class="p-0 nav-link text-white"
                           @click.prevent="navbarMinimize">
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line bg-white"></i>
                                <i class="sidenav-toggler-line bg-white"></i>
                                <i class="sidenav-toggler-line bg-white"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<style scoped>
@media (min-width: 992px) {
    :not(.main-content) .navbar .dropdown .dropdown-menu {
        top: 0.25rem !important;
    }
}
</style>
<script>
import Breadcrumbs from "/@/elements/Breadcrumbs.vue";
import {mapState, mapMutations} from "vuex";
import placeholder from "/@/assets/img/placeholder.jpg";

export default {
    name: "Navbar",
    components: {
        Breadcrumbs
    },
    emits: ['refresh'],
    data() {
        return {
            showMenu: false,
            showProfileDropdown: false,
            showAppDropdown: false,
        };
    },
    computed: {
        ...mapState(["isNavFixed", "appId"]),
        currentRouteName() {
            return this.$route.meta.pageTitle;
        },
        currentDirectory() {
            let dir = this.$route.path.split("/")[1];
            return dir.charAt(0).toUpperCase() + dir.slice(1);
        },
        loggedIn() {
            return this.$store.getters["auth/isAuthenticated"];
        },
        getProfileNavbar() {
            return this.$store.getters["profile/me"];
        },
        getNameNavbar() {
            return this.getProfileNavbar?.name
        },
        getImageNavbar() {
            return this.getProfileNavbar?.profile_image;
        },
        appList() {
            return this.$store.getters["app/list"]?.data;
        },
        selectedApp() {
            const id = this.appId ?? 0;
            return this.appList.find(x => x.id === id)?.name ?? 'Select App';
        }
    },
    async mounted() {
        await this.$store.dispatch("app/getList");
    },
    methods: {
        ...mapMutations(["navbarMinimize", "setApp"]),
        chooseApp(id) {
            this.setApp(id);
            this.$emit('refresh');
        },
        profileImage() {
            return this.getImageNavbar ? this.getImageNavbar : placeholder;
        },
        async handleLogout() {
            try {
                await this.$store.dispatch("auth/logout");
                await this.$router.push({name: "login"});
            } catch (error) {
                this.$store.dispatch('swal/show', {
                    type: "error",
                    message: "Oops, something went wrong!"
                });
            }
        },
    }
};
</script>
