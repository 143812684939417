import httpCaller from './http';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = import.meta.env.VITE_API_BASE_URL;
const jsona = new Jsona();

class ApiService {
    constructor(resource, { include = '', app = null } = {}) {
        this.resource = resource;
        this.include = include;
        this.setApp(app);
    }

    setApp(app = null) {
        this.app = app ? `apps/${app}/` : '';
    }

    getUrl(itemId = '', queryParams = '', useApp = true) {
        const baseUrl = useApp ? `${API_URL}/${this.app}${this.resource}` : `${API_URL}/${this.resource}`;
        return `${baseUrl}${itemId ? `/${itemId}` : ''}${queryParams}`;
    }

    getIncludeQuery() {
        return this.include ? `?${this.include}` : '';
    }


    parseResponse(response) {
        return jsona.deserialize(response.data);
    }

    async request(method, url, options = {}) {
        const { data = null, params = null, useParamsSerializer = false, parseResponse = true } = options;
        const config = {
            headers: authHeader(),
            params,
        };

        if (useParamsSerializer) {
            config.paramsSerializer = params => qs.stringify(params);
        }

        const isGetOrDelete = ['get', 'delete'].includes(method);
        const response = await httpCaller[method](url, isGetOrDelete ? config : data, isGetOrDelete ? undefined : config);
        return parseResponse ? this.parseResponse(response) : response;
    }

    async getAll(params) {
        const response = await this.request('get', this.getUrl('', this.getIncludeQuery(), true), { params, useParamsSerializer: true, parseResponse: false });
        return {
            data: this.parseResponse(response),
            meta: response.data.meta?.page
        };
    }

    async get(itemId) {
        return this.request('get', this.getUrl(itemId, this.getIncludeQuery(), false));
    }

    async edit(item) {
        return this.editManual(item.id, item);
    }

    async editManual(id, item) {
        const payload = jsona.serialize({ stuff: item, includeNames: [] });
        return this.request('patch', this.getUrl(id, this.getIncludeQuery(), false), { data: payload });
    }

    async create(item) {
        const payload = jsona.serialize({ stuff: item, includeNames: [] });
        return this.request('post', this.getUrl('', this.getIncludeQuery(), false), { data: payload });
    }

    async remove(itemId) {
        return this.request('delete', this.getUrl(itemId, '', false));
    }

    async uploadAsset(item, type, field) {
        const payload = new FormData();
        payload.append(field, item[type]);
        const response = await httpCaller.post(`${API_URL}/uploads/${this.resource}/${item.id}/${type}`, payload, {
            headers: authHeader()
        });
        return response.data.url;
    }
}

export default ApiService;
