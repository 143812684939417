<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">Customer Profile</h6>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Name:</strong> &nbsp; {{ customer.name }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Email:</strong> &nbsp; {{ customer.email }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Signed Up:</strong> &nbsp; {{ customer.signupDate }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Subscribed:</strong> &nbsp; {{ customer.isSubscribed ? 'Yes' : 'No' }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Status:</strong> &nbsp; {{ customer.status }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Stripe ID:</strong> &nbsp; {{ customer.stripeId }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProfileInfoCard",
    props: {
        customer: {
            type: Object,
            required: true,
        },
    },
};
</script>
