import store from "../store";

export default async function auth({ next }) {
    try {
        if (!store.getters["auth/isAuthenticated"]) {
            return next({ name: "login" });
        }

        await store.dispatch("profile/me");

        if (typeof store.getters['profile/me'] === 'undefined') {
            localStorage.removeItem('access_token');
            return next({ name: "login" });
        }

        await store.commit('setLoggedInLayout', true, { root: true });
        next();
    } catch (error) {
        console.error('Error in auth middleware:', error);
        next({ name: "error.500" }); // Navigate to an error page or handle as needed
    }
}
