import {createCrudRoutes} from "/@/helpers/createCrudRoutes";

const tag = (components) => createCrudRoutes("tag", "/content/tags", {
    components: components,
    roles: ['staff', 'admin'],
    listProps: {
        list: {
            sortDirection: '-id',
            headers: [
                {key: 'name', title: 'Name', format: 'bold'},
                {key: 'titles_published', title: 'Linked titles'},
                {key: 'createdAt', title: 'Created At', format: 'date'},
                {key: 'action', title: 'Action', format: 'actions', sortable: false}
            ],
        },
        add: {
            model: {
                type: 'tags',
                name: '',
            },
            layout: 'default',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                    ]
                ]
            }
        },
        edit: {
            layout: 'default',
            formFields: {
                main: [
                    [
                        {key: 'name', label: 'Name', type: 'text'},
                    ]
                ]
            }
        }
    }
});

export {
    tag
}