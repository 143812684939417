// src/helpers/formatColumn.js
import moment from 'moment';
import placeholder from '/@/assets/img/placeholder.jpg';

const DATE_FORMAT = 'DD MMM YYYY, hh:mm a';
const IMAGE_SIZE = '80x80';

class FormatColumn {
    addActionButton(id, action, icon) {
        return `
            <a id="${id}" class="actionButton ${action.toLowerCase()}Button cursor-pointer me-3" data-bs-toggle="tooltip" title="${action}">
                <i class="fas fa-${icon} text-muted"></i>
            </a>`;
    }

    addActionEditButton(id) {
        return this.addActionButton(id, 'Edit', 'edit');
    }

    addActionDeleteButton(id) {
        return this.addActionButton(id, 'Delete', 'trash-alt');
    }

    asDate(date) {
        return moment(date).format(DATE_FORMAT);
    }

    asUser(row) {
        const img = row.profile_image || row.image || placeholder;
        const name = `${row.name ?? ''} ${row.surname ?? ''}`.trim();
        return `
            <div class="d-flex">
                <img src="${img}" class="avatar-sm rounded-circle" alt="">
                <div class="h6 text-bold ms-3 my-auto">${name}</div>
            </div>`;
    }

    asUserAlt(row) {
        const img = row.profile_image || row.image || placeholder;
        const name = `${row.name ?? ''} ${row.surname ?? ''}`.trim();
        return `
            <div class="d-flex">
                <img src="${img}" class="avatar-sm rounded-circle" alt="">
                <div class="my-auto ms-3">
                    <strong class="d-block text-dark lh-1">${name}</strong>
                    <small>${row.email}</small>
                </div>
            </div>`;
    }

    asSimpleBold(row) {
        return `<div class="text-bold my-auto">${row?.name ?? row?.title ?? row?.id}</div>`;
    }

    asSimpleBoldManual(row) {
        return `<div class="text-bold my-auto">${row ?? ''}</div>`;
    }

    asTitleWithSlug(row) {
        return `<div>
                  <strong class="d-block text-dark lh-1">${row.title}</strong>
                  <small class="pill text-xs font-monospace">${row?.slug ?? ''}</small>
              </div>`;
    }

    asTitleWithSubtitle(row) {
        return `<div class="my-auto ms-3">
                  <strong class="d-block text-dark lh-1">${row.title}</strong>
                  <small>${row?.content ?? row?.description ?? ''}</small>
              </div>`;
    }

    asSubscribed(row) {
        const subscribed = row.subscribed ? 'Subscribed' : 'No';
        const status = row.subscribed ? 'primary' : 'light text-dark';
        return `<span class="badge badge-sm me-1 rounded-pill bg-${status.toLowerCase()}">${subscribed}</span>`;
    }

    asTitleWithCover(row) {
        const img = row.image ? row.image.replace('.png', `-${IMAGE_SIZE}.png`) : placeholder;
        const title = row.title ?? row.name ?? '';
        const authors = Array.isArray(row.authors)
            ? row.authors.map(author => `${author.name ?? ''} ${author.surname ?? ''}`.trim()).join(', ')
            : '';

        return `
            <div class="d-flex">
                <img src="${img}" width="40" class="rounded" alt="">
                <div class="my-auto ms-3">
                    <strong class="d-block text-dark lh-1">${title}</strong>
                    <small>${authors}</small>
                </div>
            </div>`;
    }

    asPills(list, options = { limit: 0, style: 'primary' }) {
        const { limit = 0, style = 'primary' } = options;
        const allTags = list.map(tag => tag.name);
        const visibleTags = limit > 0 ? allTags.slice(0, limit) : allTags;
        const remainingCount = Math.max(0, allTags.length - visibleTags.length);

        const pillsHtml = visibleTags.map(tag =>
            `<span class="badge badge-sm me-1 bg-${style}">${tag}</span>`
        ).join('');

        const countPill = remainingCount > 0
            ? `<span class="badge badge-sm me-1 bg-light text-dark" title="${allTags.join(', ')}">+ ${remainingCount}</span>`
            : '';

        return pillsHtml + countPill;
    }

    asStatus(row) {
        let status;
        if (row.status !== undefined) {
            status = row.status
        } else {
            status = row.is_active ? 'Active' : 'Inactive'
        }
        return `<span class="badge badge me-1 rounded-pill status-${status.toLowerCase()}">${status}</span>`;
    }

    asDurationWithChapters(row) {
        if (row.chapters.length === 0) {
            return '---';
        }

        let time = 0;
        row.chapters.map(chapter => time += chapter.duration ?? 0);
        const hours = Math.floor(time / 3600);
        time = time - hours * 3600;
        const minutes = Math.floor(time / 60);
        time = time - minutes * 60;

        const durationHours = hours > 0 ? `${hours}h ` : '';
        const durationMin = minutes > 0 ? `${minutes}m ` : '';
        const durationSec = time > 0 ? `${time}s ` : '';

        return `<strong>${durationHours}${durationMin}${durationSec}</strong> (${row.chapters.length} Chapters)`;
    }

    asInlineCode(row) {
        return `<span class="badge badge-lg me-1 bg-light text-danger text-lowercase font-monospace">${row}</span>`;
    }

    asCode(value) {
        if (!value) return '';
        try {
            const jsonHtml = JSON.stringify(value, null, 2)
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"(.*?)":/g, '<span class="json-key">"$1"</span>:')
                .replace(/: "(.*?)"/g, ': <span class="json-string">"$1"</span>')
                .replace(/: (true|false)/g, ': <span class="json-boolean">$1</span>')
                .replace(/: (null)/g, ': <span class="json-null">$1</span>')
                .replace(/: ([0-9]+)/g, ': <span class="json-number">$1</span>');
            return `<pre class="code-view">${jsonHtml}</pre>`;
        } catch (e) {
            return '<span class="json-error">Invalid JSON</span>';
        }
    }

    asSubtitle(value) {
        return value;
    }

    prepareFormattedRows(row, headers) {
        return headers.map(header => this.prepareFormattedRow(row, header));
    }

    prepareFormattedRow(value, header) {
        const formatters = {
            date: () => this.asDate(value[header.key]),
            actions: () => this.addActionEditButton(value.id) + this.addActionDeleteButton(value.id),
            actionEdit: () => this.addActionEditButton(value.id),
            actionDelete: () => this.addActionDeleteButton(value.id),
            tags: () => this.asPills(value[header.key], header),
            profile: () => this.asUser(value),
            customerProfile: () => this.asUserAlt(value),
            fullName: () => this.asSimpleBoldManual(`${value.name ?? ''} ${value.surname ?? ''}`),
            duration: () => this.asDurationWithChapters(value),
            titleWithCover: () => this.asTitleWithCover(value),
            titleSubtitle: () => this.asTitleWithSubtitle(value),
            titleSlug: () => this.asTitleWithSlug(value),
            bold: () => this.asSimpleBold(value),
            relation: () => this.asSimpleBold(value[header.key]),
            inlineCode: () => this.asInlineCode(value[header.key]),
            code: () => this.asCode(value[header.key]),
            status: () => this.asStatus(value),
            subtitle: () => this.asSubtitle(value[header.key]),
            subscribed: () => this.asSubscribed(value),
        };

        return header.format && formatters[header.format]
            ? formatters[header.format]()
            : `${value[header.key] ?? ''}`;
    }
}

export default new FormatColumn();
